import React from 'react';
import { Box } from '@mui/material';
import { AccountCircle as UserIcon, LocationOnOutlined as LocationIcon } from '@mui/icons-material';
import _ from 'lodash';

import type { SxProp } from '@vizsla/theme';
import { formatNumber } from '@vizsla/utils';
import { InformationCard } from '@vizsla/components';

import type { Experience } from 'src/types/experiences';

const boxStyles: SxProp = theme => ({
  display: 'flex',
  flexWrap: 'nowrap',
  gap: theme.spacing(2),
});

type CardInfo = {
  id: string;
  title: string;
  icon: React.ReactElement;
  value: number | string;
};

interface ExperiencesCardsProps {
  experiences: Experience[];
  isLoading: boolean;
}

const ExperiencesCards: React.FC<ExperiencesCardsProps> = ({ experiences, isLoading }) => {
  const totalAttendees = _.sumBy(experiences, experience => experience.attendees?.count || 0);

  // TODO: replace with real values, when possible
  // TODO: replace UserIcon in "Total Checked In" and "Total Remaining" cards

  const CARDS_INFO: CardInfo[] = [
    {
      id: 'total_attendees',
      title: 'Total Attendees',
      icon: <UserIcon color="secondary" fontSize="large" />,
      value: formatNumber(totalAttendees),
    },
    {
      id: 'total_checked_in',
      title: 'Total Checked In',
      icon: <UserIcon color="secondary" fontSize="large" />,
      value: formatNumber(0),
    },
    {
      id: 'total_remaining',
      title: 'Total Remaining',
      icon: <UserIcon color="secondary" fontSize="large" />,
      value: formatNumber(0),
    },
    {
      id: 'total_attendies',
      title: 'On-Site Registrations',
      icon: <LocationIcon color="secondary" fontSize="large" />,
      value: formatNumber(0),
    },
  ];

  return (
    <Box sx={boxStyles}>
      {CARDS_INFO.map(({ id, ...cardProps }) => (
        <InformationCard key={id} loading={isLoading} {...cardProps} />
      ))}
    </Box>
  );
};

export { ExperiencesCards };
