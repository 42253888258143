import styled from '@emotion/styled';

import { BorderRadius } from '@vizsla/theme';

/// Container

export const Container = styled.div``;

/// Popover

export const PopoverContent = styled.div`
  min-width: 30rem;
  max-width: 30rem;
  display: flex;
  flex-direction: column;
  border-radius: ${BorderRadius.M};
`;

/// Content

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${p => p.theme.spacing(0, 1, 2)};
`;
