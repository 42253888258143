import styled from '@emotion/styled';
import { CircularProgress as CircularProgressBase, Typography } from '@mui/material';

import { BorderRadius, PaletteColor, Shade } from '@vizsla/theme';

/// Container

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${p => p.theme.spacing(2)};
`;

/// Content

export const Content = styled.div`
  display: flex;
  min-height: 10rem;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: ${BorderRadius.M};
  background-color: ${Shade.Gray[50]};
  overflow: hidden;
`;

/// CircularProgress

export const CircularProgress = styled(CircularProgressBase)`
  position: absolute;
`;

CircularProgress.defaultProps = {
  size: '2rem',
  color: 'primary',
};

/// Disclaimer

export const Disclaimer = styled(Typography)`
  text-align: center;
  color: ${PaletteColor.GrayText};
`;

Disclaimer.defaultProps = {
  variant: 'caption',
};

/// InnerDisclaimer

export const InnerDisclaimer = styled(Disclaimer)`
  position: absolute;
`;
