import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Typography } from '@mui/material';

import { RouterEntitiesId } from '@vizsla/constants';
import { formatNumber, buildUrl } from '@vizsla/utils';
import type { TableColumn } from '@vizsla/components';
import { TableData, Button } from '@vizsla/components';

import type { Experience } from 'src/types/experiences';
import * as appRoutes from 'src/constants/routes';

interface ExperiencesTableProps {
  experiences: Experience[];
  isLoading: boolean;
}

// TODO: replace with real values, when possible

const ExperiencesTable: React.FC<ExperiencesTableProps> = ({ experiences, isLoading }) => {
  const navigate = useNavigate();

  const TABLE_COLUMNS: Array<TableColumn<Experience>> = [
    {
      key: 'name',
      title: 'Experiences',
      render: (_v, experience) => {
        return (
          <Typography variant="body2" fontWeight="bold">
            {experience.name}
          </Typography>
        );
      },
    },
    {
      key: 'type',
      title: 'Type',
      render: (_v, experience) => experience.experienceType,
    },
    {
      key: 'attendees',
      title: 'Attendees',
      render: (_v, experience) => formatNumber(experience.attendees?.count),
    },
    {
      key: 'checked_in',
      title: 'Checked-In',
      render: (_v, experience) => formatNumber(0),
    },
    {
      key: 'remaining',
      title: 'Remaining',
      render: (_v, experience) => formatNumber(0),
    },
    {
      key: 'actions',
      title: '',
      align: 'right',
      render: (_v, experience) => {
        const onButtonClick = () => {
          const url = buildUrl(appRoutes.checkinExperienceAttendeesPage, {
            [RouterEntitiesId.experience]: experience.id || '',
          });
          navigate(url);
        };
        return (
          <Button onClick={onButtonClick} color="secondary">
            Check In Attendees
          </Button>
        );
      },
    },
  ];

  return (
    <TableData
      dataSource={experiences}
      loading={isLoading}
      columns={TABLE_COLUMNS}
      emptyMessage="No active experiences yet"
    />
  );
};

export { ExperiencesTable };
