import React from 'react';
import { Typography, Grid } from '@mui/material';
import { useLocation, matchPath, Link } from 'react-router-dom';

import { getLocationPaths } from '@vizsla/utils';

import { MENU_ITEMS } from 'src/constants/menuItems';

interface BreadcrumbsPathProps {
  path: string;
  index: number;
}

const BreadcrumbsPath: React.FC<BreadcrumbsPathProps> = ({ path, index }) => {
  const area = MENU_ITEMS.find(item => Boolean(matchPath(item.route, path)));

  if (!area) {
    return null;
  }

  return (
    <React.Fragment>
      {index > 0 && (
        <Grid key="/" item>
          <Typography color="textSecondary">&#8594;</Typography>
        </Grid>
      )}
      <Grid key="item" item>
        <Typography component={Link} to={path} color="textSecondary">
          {area.title}
        </Typography>
      </Grid>
    </React.Fragment>
  );
};

interface BreadcrumbsProps {}

export const Breadcrumbs: React.FC<BreadcrumbsProps> = () => {
  const location = useLocation();

  const paths = getLocationPaths(location.pathname, 1);

  return (
    <Grid container spacing={1}>
      {paths.map((path, index) => (
        <BreadcrumbsPath key={path} path={path} index={index} />
      ))}
    </Grid>
  );
};
