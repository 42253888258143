import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { Typography, Theme, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { styled } from '@mui/material/styles';

import { Modal, TableData, TableColumn, Counter } from '@vizsla/components';
import { useModal, useShoppingCart } from '@vizsla/hooks';
import { useGetAssetByExperiencesListQuery, useGetAllFileListQuery } from '@vizsla/graphql';

import { MODALS } from 'src/constants/modals';
import { EMPTY_IMAGE } from 'src/assets';

const useStyles = makeStyles((theme: Theme) => ({
  modalWrapper: {
    '& .MuiDialog-container': {
      justifyContent: 'center',
    },
    '& .MuiDialog-paper': {
      width: '50%',
      maxWidth: 'unset',
      height: '60%',
      maxHeight: 'unset',
      margin: 0,
      padding: '2%',
    },
  },

  modalButton: {
    display: 'inline-flex',
    width: '100%',
  },
}));
export const AttendeeStore = ({ purchases }) => {
  const classes = useStyles();
  const [assetCart, setAssetCart] = React.useState<any[]>([]);
  const { items, add: addItem, remove: removeItem } = useShoppingCart();
  const { isOpen, closeModal } = useModal(MODALS.ATTENDEE_STORE);
  const { experienceId } = useParams();
  const {
    data,
    loading: loadingAssets,
    error,
  } = useGetAssetByExperiencesListQuery({
    variables: {
      filter: {
        experienceId: {
          equals: experienceId,
        },
      },
    },
  });
  const assetCount = optionId => {
    const count = assetCart.filter(
      item => item.type === 'asset' && item.asset.id === optionId,
    ).length;
    return count;
  };
  const addAsset = (data, price) => {
    setAssetCart([
      ...assetCart,
      {
        type: 'asset',
        name: data?.name,
        price,
        asset: {
          id: data?.id,
          name: data?.name,
          uri: data.image,
          inventoryId: data?.inventoryId,
        },
      },
    ]);
    assetCount(data?.id);
  };
  const removeAsset = optionId => {
    const item = assetCart.findIndex(item => item.type === 'asset' && item.asset.id === optionId);
    if (item !== -1) {
      const newAssetCart = [...assetCart];
      newAssetCart.splice(item, 1);
      setAssetCart([...newAssetCart]);
    }
    assetCount(optionId);
  };

  const addToCart = () => {
    for (const item of assetCart) {
      addItem(item);
    }
    setAssetCart([]);
    closeModal(MODALS.ATTENDEE_STORE);
  };
  const assets = useMemo(() => {
    return data?.getAssetByExperiencesList?.items || [];
  }, [data]);
  const pictureIds = useMemo(() => {
    return assets.map(asset => asset.picture as string) || [];
  }, [assets]);

  const { data: assetsPictures, loading: loadAssetsPictures } = useGetAllFileListQuery({
    variables: { filter: { id: { in: pictureIds } } },
  });

  const loading = useMemo(
    () => loadingAssets || loadAssetsPictures,
    [loadingAssets, loadAssetsPictures],
  );

  const picturesData = useMemo(() => {
    return assetsPictures?.filesList?.items || [];
  }, [assetsPictures]);

  const finalDataAsset = useMemo(() => {
    return assets.map((asset: any) => {
      const image = picturesData.filter(itF => itF.id === asset.picture);
      const newAsset = { ...asset };
      const { name } = asset;
      let attributes = '';
      if (asset.variantsValues != null) {
        attributes = `${JSON.stringify(asset.variantsValues)
          .replace(/"|\{|\}/gi, '')
          .replace(/,/gi, ' ')}`;
      }
      return {
        ...newAsset,
        name,
        attributes,
        price: `$${(Math.round(asset.price * 100) / 100).toFixed(2)}`,
        amount: asset.price,
        image: image[0]?.downloadUrl,
      } as any;
    });
  }, [assetsPictures]);

  const StyledImageGrid = styled(Grid)(({ theme }) => ({
    width: '100px',
    '& > img': {
      display: 'block',
      width: '100%',
      height: '100%',
      maxHeight: '100px',
      objectFit: 'contain',
    },
  }));
  interface ItemTable {
    image?: string;
    name?: string;
    attributes?: string;
    price?: string;
    quantity?: number;
    id?: string;
  }
  const { store } = purchases;
  // const dataTable: any[] = [];
  const dataTable = useMemo(() => {
    let total: number | string = 0;
    const dataTableSwagBag: any[] = [];
    if (store && store.length > 0) {
      for (const item of store) {
        const { name } = item.storeInventoryItem.storeAsset;
        let attributes = '';
        if (item.storeInventoryItem.variantsValues != null) {
          attributes = `${JSON.stringify(item.storeInventoryItem.variantsValues)
            .replace(/"|\{|\}/gi, '')
            .replace(/,/gi, ' ')}`;
        }
        const itemTable: ItemTable = {};
        itemTable.id = item.storeInventoryItem.id;
        itemTable.image = item.storeInventoryItem.storeAsset.image.downloadUrl;
        itemTable.name = name;
        itemTable.attributes = attributes;
        itemTable.price = `$${(Math.round(item.amount * 100) / 100).toFixed(2)}`;
        itemTable.quantity = 1;
        total += item.amount;
        const indexItm = dataTableSwagBag.findIndex(itm => itm.id === itemTable.id);
        if (indexItm !== -1) {
          dataTableSwagBag[indexItm].quantity += 1;
        } else {
          dataTableSwagBag.push(itemTable);
        }
      }
      total = (Math.round(total * 100) / 100).toFixed(2);
    }
    return { dataTableSwagBag, total };
  }, [store]);

  const tableColumns: TableColumn[] = [
    {
      key: 'name',
      title: 'Item',
      render: (_, storeItem) => {
        const imageSrc = storeItem.image || EMPTY_IMAGE;
        return (
          <Grid xs={10} container alignItems="center" gap={2.5}>
            <StyledImageGrid item>
              <img src={imageSrc} alt="Asset" />
            </StyledImageGrid>
            <Grid item>
              <Typography textAlign="left" fontWeight="bold">
                {storeItem.name}
              </Typography>
              <Typography textAlign="left" fontWeight="bold">
                {storeItem.attributes}
              </Typography>
            </Grid>
          </Grid>
        );
      },
    },
    {
      key: 'price',
      title: 'Price',
      render: (_, storeItem) => {
        return (
          <Grid alignItems="center">
            <Typography align="center">{storeItem.price}</Typography>
          </Grid>
        );
      },
    },
    {
      key: 'quantity',
      title: 'Quantity',
      render: (_, storeItem) => {
        if (storeItem.quantity) {
          return (
            <Grid alignItems="center">
              <Typography align="center">{storeItem.quantity}</Typography>
            </Grid>
          );
        }
        if (storeItem?.available <= 0 && storeItem?.continueSalesWhenOutOfStock === false) {
          return (
            <Grid item xs={6}>
              <Typography textAlign="center">Sold out</Typography>
            </Grid>
          );
        }
        return (
          <Grid item xs={6} alignSelf="center">
            <Counter
              value={assetCount(storeItem?.id)}
              max={storeItem.continueSalesWhenOutOfStock === 1 ? 1000000 : storeItem?.available}
              onIncrease={() => addAsset(storeItem, storeItem.amount)}
              onDecrease={() => removeAsset(storeItem?.id)}
            />
          </Grid>
        );
      },
    },
  ];

  return (
    <Modal isOpen={isOpen} onClose={closeModal} customClasses={[classes.modalWrapper]}>
      <Modal.Title>
        <Typography variant="h3" align="center" marginTop="5px">
          Purchases
        </Typography>
      </Modal.Title>
      <Typography fontSize="14px" fontWeight="bold" align="left" marginTop="5px">
        Purchased Items on Registration
      </Typography>
      <Grid item xs={12}>
        <TableData loading={false} dataSource={dataTable.dataTableSwagBag} columns={tableColumns} />
      </Grid>
      <Typography fontSize="14px" fontWeight="bold" align="right" marginTop="5px">
        Total: ${dataTable.total}
      </Typography>
      <Typography fontSize="14px" fontWeight="bold" align="left" marginTop="5px">
        Available Items to Purchase
      </Typography>
      <Grid item xs={12}>
        <TableData loading={false} dataSource={finalDataAsset} columns={tableColumns} />
      </Grid>
      <Grid xs={3}>
        <Modal.Actions>
          <Modal.Action
            type="primary"
            content={`Add to cart (${assetCart.length})`}
            onAction={() => {
              addToCart();
            }}
            disabled={false}
            loading={false}
            className={classes.modalButton}
          />
        </Modal.Actions>
      </Grid>
    </Modal>
  );
};
