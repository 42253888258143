import React from 'react';
import { useNavigate } from 'react-router-dom';

import { useCurrentUser, useAppAuth } from '@vizsla/hooks';
import { FullscreenLoader } from '@vizsla/components';

import * as appRoutes from 'src/constants/routes';
import { useAllowedRoutes } from 'src/hooks/authorization';

export const AuthResolver: React.FC = () => {
  const navigate = useNavigate();

  const { isAuthenticated } = useAppAuth();
  const { loading: userLoading } = useCurrentUser();
  const { startRoutePath } = useAllowedRoutes();

  React.useEffect(() => {
    if (isAuthenticated && !userLoading) {
      navigate(startRoutePath);
    }
    navigate(appRoutes.authLogin);
  }, [navigate, isAuthenticated, startRoutePath, userLoading]);

  return <FullscreenLoader />;
};
