import React from 'react';
import { List, ListItemIcon, Paper, Skeleton, styled } from '@mui/material';

import { Shade } from '@vizsla/theme';
import { NavItem } from '@vizsla/components';

import { VIZSLA_LOGO } from 'src/assets';
import { MENU_ITEMS } from 'src/constants/menuItems';

interface StyleProps {
  isExpanded: boolean;
}

const StyledPaper = styled(Paper)<StyleProps>(({ theme, isExpanded }) => ({
  gridArea: 'sidebar',
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  padding: theme.spacing(1),
  maxWidth: isExpanded ? 300 : 70,
  minWidth: isExpanded ? 240 : 70,
  zIndex: 3,
  backgroundColor: theme.customPalette.nav.item.background.primary,
}));

const StyledLogo = styled('img')(({ theme }) => ({
  objectFit: 'contain',
  display: 'flex',
  padding: theme.spacing(0, 0, 2.5),
  height: 76,
  width: '100%',
}));

interface SidebarProps {
  isLoading: boolean;
}

export const Sidebar: React.FC<SidebarProps> = ({ isLoading }) => {
  const isExpanded = false;

  const renderListItems = React.useCallback(() => {
    return MENU_ITEMS.map(menuItem => {
      if (isLoading) {
        return (
          <Skeleton
            key={menuItem.route}
            sx={{ bgcolor: Shade.Gray[400] }}
            variant="rectangular"
            height={50}
            width="100%"
          />
        );
      }

      return (
        <NavItem
          key={menuItem.route}
          to={menuItem.route}
          icon={menuItem.icon}
          title={menuItem.title}
          isExpanded={isExpanded}
        />
      );
    });
  }, [isExpanded, isLoading]);

  return (
    <StyledPaper isExpanded={isExpanded} square elevation={4}>
      <List disablePadding>
        <ListItemIcon>
          <StyledLogo src={VIZSLA_LOGO} alt="Vizsla logo" />
        </ListItemIcon>

        {renderListItems()}
      </List>
    </StyledPaper>
  );
};
