import React from 'react';

import { useCurrentUser } from '@vizsla/hooks';
import { FullscreenLoader } from '@vizsla/components';

import { OrganizationLayout } from './OrganizationLayout/OrganizationLayout';

export const LayoutResolver: React.FC = ({ children }) => {
  const { user, loading: userLoading } = useCurrentUser();

  if (userLoading || !user) {
    return <FullscreenLoader />;
  }

  return <OrganizationLayout>{children}</OrganizationLayout>;
};
