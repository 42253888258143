import React from 'react';
import styled from '@emotion/styled';
import { useMutation } from '@apollo/client';
import { Checkbox, Grid, Skeleton, Typography } from '@mui/material';
import { Form } from 'react-final-form';

import { useModal, useNotification } from '@vizsla/hooks';
import { Modal } from '@vizsla/components';
import { Button as ButtonBase } from '@vizsla/components';
import { useGetAllFileListQuery, useGetSwagBagAssetsByOptionQuery } from '@vizsla/graphql';

import { MODALS } from 'src/constants/modals';

import { SwagBagName } from './components/SwagBagName';
import { NEW_ORDER_INTENT, ORDER_INTENT_TO_ORDER } from '../../graphql/orders';
import { SwagbagItem } from './components/SwagbagItem';

/// Content

const Content = styled.div`
  margin-top: 2rem;
`;

/// Buttons

const Buttons = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
`;

/// Button
const Button = styled(ButtonBase)`
  margin: 0;
`;
export const AttendeeSwagBagModal = ({ swagBag, regOptionId, orderId }) => {
  const { isOpen, closeModal } = useModal(MODALS.ATTENDEE_SWAGBAG);
  const notification = useNotification();
  const [newOrderIntent] = useMutation(NEW_ORDER_INTENT);
  const [newOrderIntentToOrder] = useMutation(ORDER_INTENT_TO_ORDER);
  const [loadingButton, setLoadingButton] = React.useState(false);
  const [disabledButton, setDisabledButton] = React.useState(false);
  const [disableCheck, setDisabledCheck] = React.useState(false);
  const createNewOrderIntent = async data => {
    try {
      return await newOrderIntent({
        variables: {
          data,
        },
      });
    } catch (error) {
      console.error(error);
      notification.error('Fail creating orderIntent');
      return null;
    }
  };
  const createNewOrderIntentToOrder = async data => {
    try {
      return await newOrderIntentToOrder({
        variables: {
          data,
        },
      });
    } catch (error) {
      console.error(error);
      notification.error('Fail creating order');
      return null;
    }
  };
  const {
    data,
    loading: loadingAssets,
    error,
  } = useGetSwagBagAssetsByOptionQuery({
    variables: {
      filter: {
        registrationOptionAId: {
          equals: regOptionId,
        },
      },
    },
  });
  const assets = React.useMemo(() => {
    return data?.assetByRegistrationOptionViewsList?.items || [];
  }, [data]);

  const pictureIds = React.useMemo(() => {
    return assets.map(asset => asset.picture as string) || [];
  }, [assets]);

  const { data: assetsPictures, loading: loadAssetsPictures } = useGetAllFileListQuery({
    variables: { filter: { id: { in: pictureIds } } },
  });

  const picturesData = React.useMemo(() => {
    return assetsPictures?.filesList?.items || [];
  }, [assetsPictures]);

  const [cartData, setCartData] = React.useState<any[]>([
    {
      id: 'L_w2UeNHPoH-7DNF-Z05G',
      name: '',
      type: 'registration',
      price: 0,
      option: {
        id: regOptionId,
        name: '',
      },
    },
  ]);
  React.useEffect(() => {}, [cartData]);
  const finalData = assets.map((asset: any) => {
    const image = picturesData.filter(itF => itF.id === asset.picture);
    return { ...asset, uri: image[0]?.downloadUrl } as any;
  });

  const addAsset = data => {
    setCartData([
      ...cartData,
      {
        type: 'swagBag',
        name: data?.name,
        price: 0,
        swagBag: {
          id: data?.id,
          name: data?.name,
          uri: data.uri,
          inventoryId: data?.inventoryId,
          pictureId: data?.picture,
          optionId: data?.registrationOptionAId,
          cartId: data?.cartId,
        },
      },
    ]);
  };

  const removeAsset = asset => {
    if (asset) {
      const data = cartData;
      const index = data.findIndex(
        item =>
          item.type === 'swagBag' &&
          item.swagBag.pictureId === asset?.picture &&
          item.swagBag.optionId === asset?.registrationOptionAId &&
          item.swagBag.cartId === asset?.cartId,
      );
      data.splice(index, 1);
      setCartData(data);
    }
  };

  const validateCheck = asset => {
    const swagBagComplete = swagBag.filter(item => item?.status === 'Completed');
    if (
      swagBagComplete.find(item => item.storeInventoryItem?.id === asset?.inventoryId) &&
      cartData.length === 1
    ) {
      return true;
    }
    const swagBagOption = cartData.find(
      item =>
        item.type === 'swagBag' &&
        item.swagBag.pictureId === asset?.picture &&
        item.swagBag.optionId === asset?.registrationOptionAId &&
        item.swagBag.inventoryId === asset?.inventoryId &&
        item.swagBag.cartId === asset?.cartId,
    );
    return Boolean(swagBagOption);
  };

  const handleChange = asset => {
    const swagBagOption = cartData.find(
      item =>
        item.type === 'swagBag' &&
        item.swagBag.pictureId === asset?.picture &&
        item.swagBag.optionId === asset?.registrationOptionAId &&
        item.swagBag.cartId === asset?.cartId,
    );
    if (swagBagOption) {
      removeAsset(asset);
      addAsset(asset);
    } else {
      addAsset(asset);
    }
  };
  const renderItemSwagBag = (assetItem: any) => {
    if (swagBag.filter(item => item.status === 'Returned').length > 0) {
      setDisabledCheck(true);
      setDisabledButton(true);
    }
    return (
      <Grid
        id={assetItem?.id}
        key={assetItem?.id}
        container
        spacing={2}
        xs={12}
        flexDirection="row"
        mb="50px"
      >
        <SwagBagName storeItem={assetItem} />

        <Grid justifyContent="center" alignItems="center" display="flex">
          <Checkbox
            checked={validateCheck(assetItem)}
            onChange={() => handleChange(assetItem)}
            disabled={disableCheck}
          />
        </Grid>
      </Grid>
    );
  };
  const renderListSwagBag = () => {
    return cartData.map(cartOption => {
      if (cartOption?.type === 'registration') {
        const optionList = finalData.filter(
          item => item.registrationOptionAId === cartOption?.option?.id,
        );
        return (
          <Grid key={cartOption.id} item xs={12}>
            <Grid item>
              {optionList.map(assetItem => {
                return renderItemSwagBag({ ...assetItem, cartId: cartOption.id });
              })}
            </Grid>
          </Grid>
        );
      }
      return null;
    });
  };

  const handleSubmit = async () => {
    setLoadingButton(true);
    const itemsSwagbag = cartData.filter(item => item.type === 'swagBag');
    if (itemsSwagbag.length === 0) {
      notification.error('items swagbag not found');
      return;
    }
    const swagBagFulfillmentIds = swagBag.map(item => item.id);
    const orderIntent = await createNewOrderIntent({
      data: itemsSwagbag,
      parentOrderId: orderId,
    });
    const orderIntentId = orderIntent?.data?.orderIntentCreate?.id;
    const orderIntentToOrder = await createNewOrderIntentToOrder({
      orderIntentId,
    });
    notification.success('Successfully Swagbag Updated');
    setDisabledButton(true);
    setLoadingButton(false);
    setDisabledCheck(true);
    closeModal();
  };
  // TODO CHANGE SWAGBAG LOGIC
  return (
    <Modal isOpen={isOpen} onClose={closeModal} fullWidth>
      <Modal.Title>
        <Typography variant="h3" align="center" marginTop="3px">
          Swag Bag
        </Typography>
      </Modal.Title>
      <Form onSubmit={handleSubmit}>
        {form => (
          <Content>
            {loadingAssets && (
              <React.Fragment>
                <Skeleton variant="rectangular" height={48} />
                <Skeleton variant="rectangular" height={48} />
                <Skeleton variant="rectangular" height={48} />
              </React.Fragment>
            )}

            {!loadingAssets && !loadAssetsPictures && renderListSwagBag()}

            <Buttons>
              <Button onClick={form.handleSubmit} loading={loadingButton} disabled={disabledButton}>
                Update
              </Button>
            </Buttons>
          </Content>
        )}
      </Form>
    </Modal>
  );
};
