import React from 'react';

import type { SxProp } from '@vizsla/theme';
import { PaperBlock } from '@vizsla/components';

import type { Campaign } from 'src/types/campaigns';
import { CampaignExperiencesInfo } from 'src/components/experiences';

import { CampaignBlockTitle } from '.';

const paperBlockStyles: SxProp = theme => ({
  '&:not(:first-child)': {
    mt: theme.spacing(3),
  },
});

interface CampaignBlockProps {
  campaign: Campaign;
  opened: boolean;
  handleOpen: () => void;
}

const CampaignBlock: React.FC<CampaignBlockProps> = ({ campaign, opened, handleOpen }) => {
  return (
    <PaperBlock
      sx={paperBlockStyles}
      titleContent={<CampaignBlockTitle campaign={campaign} />}
      collapsing
      collapsed={opened}
      collapsedSize={120}
      onCollapse={isCollapsed => isCollapsed && handleOpen()}
    >
      <CampaignExperiencesInfo campaignId={campaign.id || ''} isActive={opened} />
    </PaperBlock>
  );
};

export { CampaignBlock };
