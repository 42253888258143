import React from 'react';
import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { useModal } from '@vizsla/hooks';
import { Modal } from '@vizsla/components';

import { MODALS } from 'src/constants/modals';

import { AttendeesEditUserInfoContent } from '../../AttendeesEditUserInfoContent';

const useStyles = makeStyles((theme: Theme) => ({
  modalWrapper: {
    '& .MuiDialog-container': {
      justifyContent: 'flex-end',
    },
    '& .MuiDialog-paper': {
      width: '80%',
      maxWidth: 'unset',
      height: '100%',
      maxHeight: 'unset',
      margin: 0,
    },
  },
}));

const AttendeesEdituserInfoModal: React.FC = () => {
  const { isOpen, closeModal, args } = useModal(MODALS.ATTENDEE_EDIT_USERINFO_MODAL);

  return (
    <Modal isOpen={isOpen} slideDirection="left" onClose={closeModal}>
      <Modal.Title />

      <Modal.Content>
        <AttendeesEditUserInfoContent />
      </Modal.Content>
    </Modal>
  );
};

export { AttendeesEdituserInfoModal };
