import type { MenuItems } from 'src/types/menuItems';

import * as appRoutes from './routes';

export const MENU_ITEMS: MenuItems = [
  {
    title: 'Check-In',
    icon: 'AddTask',
    route: appRoutes.checkin,
  },
];
