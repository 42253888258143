import type { RouteProps } from '@vizsla/types';

import * as appRoutes from 'src/constants/routes';

import { AuthResolver } from './AuthResolver';
import { AuthConfirmationEmailPage } from './AuthConfirmationEmailPage';
import { AuthRegistrationPage } from './AuthRegistrationPage';
import { AuthLoginPage } from './AuthLoginPage';
import { AuthChangePasswordPage } from './AuthChangePasswordPage';
import { AuthCompleteRegistrationPage } from './AuthCompleteRegistrationPage';

export const authRoutes: RouteProps[] = [
  {
    index: true,
    element: AuthResolver,
  },
  {
    path: appRoutes.authRegistration,
    element: AuthRegistrationPage,
  },
  {
    path: appRoutes.authConfirmationEmail,
    element: AuthConfirmationEmailPage,
  },
  {
    path: appRoutes.authLogin,
    element: AuthLoginPage,
  },
  {
    path: appRoutes.authChangePassword,
    element: AuthChangePasswordPage,
  },
  {
    path: appRoutes.authCompleteRegistration,
    element: AuthCompleteRegistrationPage,
  },
];
