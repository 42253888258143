export const MODALS = {
  CAMPAIGN_CREATE_MODAL: 'CAMPAIGN_CREATE_MODAL',
  CAMPAIGN_DELETE_MODAL: 'CAMPAIGN_DELETE_MODAL',
  CAMPAIGN_ADD_STAFF_MODAL: 'CAMPAIGN_ADD_STAFF_MODAL',
  CAMPAIGN_STAFF_LIST_MODAL: 'CAMPAIGN_STAFF_LIST_MODAL',
  CAMPAIGN_QUESTION_CREATE_MODAL: 'CAMPAIGN_QUESTION_CREATE_MODAL',
  CAMPAIGN_QUESTION_EDIT_MODAL: 'CAMPAIGN_QUESTION_EDIT_MODAL',
  CAMPAIGN_FAQ_EDIT_MODAL: 'CAMPAIGN_FAQ_EDIT_MODAL',
  CAMPAIGN_FAQ_DELETE_MODAL: 'CAMPAIGN_FAQ_DELETE_MODAL',
  CAMPAIGN_FAQ_ADD_MODAL: 'CAMPAIGN_FAQ_ADD_MODAL',
  CAMPAIGN_DISCOUNTS_CREATE_MODAL: 'CAMPAIGN_DISCOUNTS_CREATE_MODAL',
  CAMPAIGN_ADD_OFFLINE_DONATION_MODAL: 'CAMPAIGN_ADD_OFFLINE_DONATION_MODAL',
  REGISTRATION_OPTION_EDIT_MODAL: 'REGISTRATION_OPTION_EDIT_MODAL',
  REGISTRATION_OPTION_CREATE_MODAL: 'REGISTRATION_OPTION_CREATE_MODAL',
  EARLY_BIRD_REGISTRATION_CREATE_MODAL: 'EARLY_BIRD_REGISTRATION_CREATE_MODAL',
  ORGANIZATION_CREATE_MODAL: 'ORGANIZATION_CREATE_MODAL',
  EXPERIENCE_CREATE_MODAL: 'EXPERIENCE_CREATE_MODAL',
  EXPERIENCE_EDIT_MODAL: 'EXPERIENCE_EDIT_MODAL',
  EXPERIENCE_VENUE_CREATE_MODAL: 'EXPERIENCE_VENUE_CREATE_MODAL',
  CONFIRMATION_MODAL: 'CONFIRMATION_MODAL',
  CONFIRMATION_DELETE_MODAL: 'CONFIRMATION_DELETE_MODAL',
  TICKETING_OPTION_EDIT_MODAL: 'TICKETING_OPTION_EDIT_MODAL',
  TICKETING_OPTION_CREATE_MODAL: 'TICKETING_OPTION_CREATE_MODAL',
  TEST_TRANSACTION_CREATE_MODAL: 'TEST_TRANSACTION_CREATE_MODAL',
  TEAM_RT_CREATE_MODAL: 'TEAM_RT_CREATE_MODAL',
  STORE_ASSET_CREATE_MODAL: 'STORE_ASSET_CREATE_MODAL',
  STORE_ASSET_UPDATE_IMAGE_MODAL: 'STORE_ASSET_UPDATE_IMAGE_MODAL',
  STORE_ASSET_DELETE_IMAGE_MODAL: 'STORE_ASSET_DELETE_IMAGE_MODAL',
  STORE_ASSET_INVENTORY_ITEM_CREATE_MODAL: 'STORE_ASSET_INVENTORY_ITEM_CREATE_MODAL',
  STORE_ASSET_DELETE_MODAL: 'STORE_ASSET_DELETE_MODAL',
  STORE_ASSET_INVENTORY_ADD_MODAL: 'STORE_ASSET_INVENTORY_ADD_MODAL',
  STORE_ASSET_INVENTORY_ADJUST_MODAL: 'STORE_ASSET_INVENTORY_ADJUST_MODAL',
  EXPERIENCE_STORE_ASSET_ADD_MODAL: 'EXPERIENCE_STORE_ASSET_ADD_MODAL',
  EXPERIENCE_STORE_ASSET_UPDATE_MODAL: 'EXPERIENCE_STORE_ASSET_UPDATE_MODAL',
  EXPERIENCE_STORE_ASSET_DELETE_MODAL: 'EXPERIENCE_STORE_ASSET_DELETE_MODAL',
  DONATION_OPTION_MODAL: 'DONATION_OPTION_MODAL',
  BIB_POOL_CREATE_MODAL: 'BIB_POOL_CREATE_MODAL',
  BIB_POOL_UPDATE_MODAL: 'BIB_POOL_UPDATE_MODAL',
  INDIVIDUAL_BIB_CREATE_MODAL: 'INDIVIDUAL_BIB_CREATE_MODAL',
  RESET_BIB_ASSIGNMENT_MODAL: 'RESET_BIB_ASSIGNMENT_MODAL',
  PROGRAM_CREATE_MODAL: 'PROGRAM_CREATE_MODAL',
  EXPERIENCE_HEADER_IMAGES_UPDATE_MODAL: 'EXPERIENCE_HEADER_IMAGES_UPDATE_MODAL',
  ATTENDEES_REGISTRATION_OPTION_EDIT_MODAL: 'ATTENDEES_REGISTRATION_OPTION_EDIT_MODAL',
  ATTENDEE_EDIT_USERINFO_MODAL: 'ATTENDEE_EDIT_USERINFO_MODAL',
  ATTENDEE_CHECKIN: 'ATTENDEE_CHECKIN',
  ATTENDEES_WAIVER: 'ATTENDEES_WAIVER',
  ATTENDEE_STORE: 'ATTENDEE_STORE',
  ATTENDEE_SWAGBAG: 'ATTENDEE_SWAGBAG',
  ATTENDEES_DONATION: 'ATTENDEES_DONATION',
  ATTENDEES_REGISTRATION_RECEIPT: 'ATTENDEES_REGISTRATION_RECEIPT',
};
