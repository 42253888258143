import React from 'react';
import { Alert } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';

import { ShoppingCart } from '@vizsla/components';
import { PaymentProvider } from '@vizsla/types';
import { buildUrl } from '@vizsla/utils';
import { useNotification, usePaymentGateway, useShoppingCart } from '@vizsla/hooks';

import * as appRoutes from 'src/constants/routes';

import { Button, Buttons, Container, Header, HeaderTitle } from './CheckoutPage.style';

export const CheckoutPageLayout = () => {
  const [loading, setLoading] = React.useState(false);
  const { items, checkout } = useShoppingCart();
  const { initialize } = usePaymentGateway();
  const { experienceId, parentOrderId } = useParams();

  const toaster = useNotification();
  const navigate = useNavigate();

  const openPayment = () => {
    const destination = buildUrl(appRoutes.checkinExperiencePagePayment, { experienceId });
    navigate(destination);
  };

  const handleNext = async () => {
    setLoading(true);
    const response = await checkout({
      experience: { id: experienceId },
      parentOrder: { id: parentOrderId },
    });

    if (!response) {
      toaster.error("There's no any response from the application. Please try again.");
      return;
    }

    if (!response.needPayment) {
      const destination = buildUrl(appRoutes.checkinExperienceAttendeesPage, { experienceId });
      navigate(destination);
      toaster.success('Payment Succesfully');
      setLoading(false);
      return;
    }

    if (response.paymentIntent) {
      const provider = response.paymentIntent.provider as PaymentProvider;
      const metadata = response.paymentIntent.metadata ?? {};

      initialize(provider, metadata);
      openPayment();
    }
  };

  return (
    <React.Fragment>
      <Container>
        <Header>
          <HeaderTitle>Billing Information</HeaderTitle>
        </Header>

        {/** @adwher https://8base-dev.atlassian.net/browse/VSL-270?focusedCommentId=29334 */}

        <Alert title="Hello" severity="warning">
          Lorem ipsum dolor sit amet.
        </Alert>
      </Container>

      <Buttons>
        <Button loading={loading} disabled={false} onClick={handleNext}>
          Continue
        </Button>
      </Buttons>
      <ShoppingCart />
    </React.Fragment>
  );
};
