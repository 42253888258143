import React from 'react';

import { useAttendeOrderDataListQuery } from '@vizsla/graphql';

export const useUserOrders = (attendeeId?: string, experienceId?: string) => {
  const userOrdersVariables = React.useMemo(
    () => ({
      registrationFilter: {
        registrationOption: {
          experience: {
            id: {
              equals: experienceId,
            },
          },
        },
      },
      assetFilter: {
        storeInventoryItem: {
          storeAsset: {
            experiencesStoreAssets: {
              some: { experience: { id: { equals: experienceId } } },
            },
          },
        },
      },
      attendeeId,
    }),
    [attendeeId, experienceId],
  );

  const { data, loading, refetch } = useAttendeOrderDataListQuery({
    variables: userOrdersVariables,
  });

  const userData = React.useMemo(() => data?.attendee?.user, [data]);

  return { data, userData, loading, refetchAttendeeData: refetch };
};
