import React from 'react';
import { Link } from 'react-router-dom';
import { Typography, List, ListItem, Grid, Divider, Box } from '@mui/material';
import HowToRegOutlinedIcon from '@mui/icons-material/HowToRegOutlined';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Button, { ButtonProps } from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import { CheckCircleOutline } from '@mui/icons-material';

import { Modal, UserAvatar } from '@vizsla/components';
import { useModal, useNotification } from '@vizsla/hooks';

import { MODALS } from 'src/constants/modals';

export const AttendeeCheckin = ({ attenddeChecked, experience, data }) => {
  const { isOpen, closeModal } = useModal(MODALS.ATTENDEE_CHECKIN);
  const url = `/checkin/${experience}/attendees/`;
  const CheckInButton = styled(Button)<ButtonProps>(({ theme }) => ({
    height: '36px',
    minWidth: '130px',
    borderRadius: '8px',
    backgroundColor: '#1B3C84',
    color: '#FFFFFF',
    fontSize: '14px',
    fontWeight: 'bold',
    letterSpacing: 0,
    fontFamily: 'Inter',
    lineHeight: '20px',
    textAlign: 'center',
    '&:hover': {
      backgroundColor: '#1B3C84',
      color: '#FFFFFF',
    },
  }));

  const DisableCheckInButton = styled(Button)<ButtonProps>(({ theme }) => ({
    height: '36px',
    minWidth: '130px',
    borderRadius: '8px',
    backgroundColor: '#1B3C84',
    color: '#FFFFFF',
    fontSize: '14px',
    fontWeight: 'bold',
    letterSpacing: 0,
    fontFamily: 'Inter',
    lineHeight: '20px',
    textAlign: 'center',
    opacity: 0.2,
    '&:hover': {
      backgroundColor: '#1B3C84',
      color: '#FFFFFF',
    },
  }));

  const closeModalCheckIn = () => {
    closeModal(MODALS.ATTENDEE_CHECKIN);
  };
  const AttendeesNoChecked = data.filter(item => item.checked === false).length;
  return (
    <Modal isOpen={isOpen} onClose={closeModal} fullWidth>
      <Grid container spacing={0} direction="column" alignItems="center" justifyContent="center">
        <Grid item xs={3}>
          <HowToRegOutlinedIcon
            sx={{
              fontSize: 85,
              color: '#07C27D',
            }}
          />
        </Grid>
      </Grid>
      <Modal.Title>
        <Typography variant="h3" align="center" marginTop="5px">
          {attenddeChecked}
        </Typography>
        <Typography variant="h3" align="center" marginTop="2px">
          was successfully checked in.
        </Typography>
      </Modal.Title>
      <Typography fontSize="14px" align="center" marginTop="5px">
        This registration has {AttendeesNoChecked} people pending the check in.
      </Typography>
      <List>
        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justifyContent="center"
          marginX={4}
        >
          {data.map(attendee => {
            const checkIn = attendee.checked;
            return (
              <ListItem key={attendee.id}>
                <Grid>
                  <UserAvatar user={attendee} size="48px" />
                </Grid>
                <Grid marginLeft="13px">
                  <Typography fontSize="18px" fontWeight="bold">
                    {attendee.name}
                    {checkIn ? (
                      <CheckCircleIcon
                        sx={{
                          fontSize: 20,
                          color: '#07C27D',
                          alignItems: 'center',
                          marginInline: '5px',
                          marginTop: '1px',
                          paddingTop: '1px',
                        }}
                      />
                    ) : (
                      <CheckCircleOutline
                        sx={{
                          fontSize: 20,
                          color: '#A0A7AF',
                          alignItems: 'center',
                          marginInline: '5px',
                          marginTop: '1px',
                          paddingTop: '1px',
                        }}
                      />
                    )}
                  </Typography>
                  <Typography fontSize="14px">{attendee?.registrationOption}</Typography>
                </Grid>
                <Grid item xs={3} alignItems="rigth" ml={14}>
                  {!checkIn ? (
                    <Box component={Link} to={`${url}${attendee.id}`} onClick={closeModalCheckIn}>
                      <CheckInButton> Check In </CheckInButton>
                    </Box>
                  ) : (
                    <DisableCheckInButton> Checked In </DisableCheckInButton>
                  )}
                </Grid>
              </ListItem>
            );
          })}
        </Grid>
      </List>
    </Modal>
  );
};
