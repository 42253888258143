import React from 'react';
import { Box, Grid } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import * as yup from 'yup';

import { PaginationProvider } from '@vizsla/providers';
import { ID_8BASE, RouterEntitiesId } from '@vizsla/constants';
import { buildUrl } from '@vizsla/utils';
import { useNotification } from '@vizsla/hooks';

import { AttendeesQrScanner, AttendeesTable } from 'src/components/attendees';
import { AttendeesTableDataProvider } from 'src/providers';
import * as ROUTES from 'src/constants/routes';

const ExperienceAttendeesPageLayout: React.FC = () => {
  const [code, setCode] = React.useState<string>();

  const navigate = useNavigate();
  const toast = useNotification();

  const { experienceId } = useParams();

  const handleReadQR = async (content: string) => {
    if (code === content) return;

    const schema = yup.string().matches(ID_8BASE).length(25);
    const valid = await schema.isValid(content);

    if (!valid) {
      setCode(content);
      toast.error('No attendee ID was found.');

      return;
    }

    const url = buildUrl(ROUTES.checkinExperienceAttendeeCheckinPage, {
      [RouterEntitiesId.experience]: experienceId,
      attendeeId: content,
    });

    navigate(url);
  };

  return (
    <Box>
      <Grid container gap={2} flexDirection="row" flexWrap="nowrap">
        <Grid item xs={4}>
          <AttendeesQrScanner onRead={handleReadQR} />
        </Grid>

        <Grid item xs={8}>
          <PaginationProvider>
            <AttendeesTableDataProvider>
              <AttendeesTable />
            </AttendeesTableDataProvider>
          </PaginationProvider>
        </Grid>
      </Grid>
    </Box>
  );
};

export { ExperienceAttendeesPageLayout };
