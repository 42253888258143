import React from 'react';
import { Toolbar, IconButton, Paper, styled } from '@mui/material';
import { FormatIndentDecrease, FormatIndentIncrease } from '@mui/icons-material';

import { ProfilePopover } from 'src/components/shared';

import { Breadcrumbs } from './Breadcrumbs';

const StyledPaper = styled(Paper)(() => ({
  gridArea: 'header',
  zIndex: 2,
}));

const StyledExpandIconButton = styled(IconButton)(({ theme }) => ({
  '& .MuiSvgIcon-root': {
    fill: theme.customPalette.typography.color.default,
  },
}));

export const Header: React.FC = () => {
  const isExpanded = false;

  return (
    <StyledPaper square elevation={1}>
      <Toolbar>
        <StyledExpandIconButton size="large">
          {isExpanded ? <FormatIndentDecrease /> : <FormatIndentIncrease />}
        </StyledExpandIconButton>

        <Breadcrumbs />
        <ProfilePopover />
      </Toolbar>
    </StyledPaper>
  );
};
