import React from 'react';
import { styled } from '@mui/material';

import { useCurrentOrganization } from '@vizsla/hooks';
import { FullscreenLoader } from '@vizsla/components';

import { EmptyOrganizationScreen } from 'src/components/organiaztion';

import { Sidebar } from './Sidebar';
import { Header } from './Header';

const StyledRoot = styled('div')(() => ({
  height: '100%',
  backgroundColor: '#fff',
  color: '#34b9ff',

  display: 'grid',
  gridTemplateAreas: '"sidebar header" "sidebar content"',
  gridTemplateRows: '64px 1fr',
  gridTemplateColumns: 'auto 1fr',
}));

const StyledContent = styled('div')(() => ({
  height: 'calc(100vh - 64px)',
  gridArea: 'content',
  overflow: 'auto',
  padding: '16px 16px 0',

  '&::after': {
    display: 'block',
    content: '""',
    height: 10,
    width: '100%',
  },
}));

export const OrganizationLayout: React.FC = ({ children }) => {
  const { organization, organizationIsLoading } = useCurrentOrganization();

  const renderPageContent = React.useCallback(
    (children: React.ReactNode) => {
      if (organizationIsLoading) {
        return <FullscreenLoader variant="light" />;
      }

      if (!organization) {
        return <EmptyOrganizationScreen />;
      }

      return <React.Fragment>{children}</React.Fragment>;
    },
    [organization, organizationIsLoading],
  );

  return (
    <StyledRoot>
      <Sidebar isLoading={organizationIsLoading} />
      <Header />
      <StyledContent>{renderPageContent(children)}</StyledContent>
    </StyledRoot>
  );
};
