import React from 'react';
import { Grid, Typography, styled, Box } from '@mui/material';
import { isNull } from 'lodash';

import { PaletteColor } from '@vizsla/theme';

interface Props {
  isfirst?: boolean;
  isStatus?: boolean;
  textTitle?: string;
  textBody?: string;
}

const RegistrationReceptionTextItem: React.FC<Props> = ({
  isfirst = false,
  textTitle = '',
  textBody = '',
  isStatus = false,
}) => {
  return (
    <Grid container>
      {isfirst && (
        <Box height="1px" bgcolor="#ccc" mt={4} mb={1} style={{ opacity: 0.5 }} width="100%" />
      )}

      <Grid item flexDirection="row" display="flex" width="100%">
        <Typography ml={2} minWidth={96}>
          {textTitle}{' '}
        </Typography>
        {!isStatus ? (
          <Typography>{textBody} </Typography>
        ) : (
          <Typography
            bgcolor={textBody === 'Completed' ? PaletteColor.SuccessMain : PaletteColor.ErrorMain}
            borderRadius={2}
            pl={1}
            pr={1}
            color={PaletteColor.White}
          >
            {textBody}
          </Typography>
        )}
      </Grid>

      <Box height="1px" bgcolor="#ccc" mt={1} mb={1} style={{ opacity: 0.5 }} width="100%" />
    </Grid>
  );
};

export { RegistrationReceptionTextItem };
