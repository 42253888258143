import React from 'react';

import { CheckinCampaignsPageLayout } from 'src/layouts';

const CheckinCampaignsPage: React.FC = () => {
  return (
    <CheckinCampaignsPageLayout>
      <React.Fragment />
    </CheckinCampaignsPageLayout>
  );
};

export { CheckinCampaignsPage };
