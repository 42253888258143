import React from 'react';
import { useParams } from 'react-router-dom';
import { Typography } from '@mui/material';

import {
  useAttendeesRegistrationOptionsQuery,
  useUpdateRegistrationOptionMutation,
} from '@vizsla/graphql';
import { ShoppingCartData } from '@vizsla/types';
import { useCurrentUser, useModal, useNotification, useShoppingCart } from '@vizsla/hooks';
import { Modal } from '@vizsla/components';

import { MODALS } from 'src/constants/modals';
import { ItemAttendeesRegistrationEdit } from 'src/components/experiences/CampaignExperiencesInfo/components/index';

// import { ExperienceType} from '../../types/experiences'
const AttendeesRegistrationEditModal = ({ setRegistration }) => {
  const { user } = useCurrentUser();
  const { items: itemsCart, add: addItem, remove: removeItem, clean } = useShoppingCart();
  const { isOpen, closeModal } = useModal(MODALS.ATTENDEES_REGISTRATION_OPTION_EDIT_MODAL);
  const notification = useNotification();
  // Query data Modal
  const { attendeeId, experienceId } = useParams();
  const { data, loading } = useAttendeesRegistrationOptionsQuery({
    variables: {
      id: attendeeId,
    },
  });
  const registrationOptionsData = data?.attendee ?? {};
  // mutation updateOptions
  const [registrationDataUpdate, setRegistrationDataUpdate] = React.useState<ShoppingCartData>({
    name: '',
    price: 0,
    type: 'registration',
    option: {
      id: '',
      name: '',
    },
  });

  const [updateRegistrationOptionMutation, { loading: loadingData }] =
    useUpdateRegistrationOptionMutation({
      refetchQueries: ['UserAttendees'],
    });

  const [typeDonationRefund, setTypeDonationRefund] = React.useState({
    type: 'Donation',
    valid: false,
    price: 0,
  });

  const onSubmit = async () => {
    try {
      if (registrationDataUpdate.type === 'registration') {
        if (registrationDataUpdate.option.id !== '') {
          const itemRemove = itemsCart.filter(item => item.type === 'registration');
          if (itemRemove.length > 0) {
            clean();
          }
          registrationDataUpdate.attendee = {
            firstName: String(data?.attendee?.user?.firstName),
            lastName: String(data?.attendee?.user?.lastName),
            email: String(data?.attendee?.user?.email),
            phone: '',
            birthday: '',
            gender: '',
            reminders: false,
            address: '',
            city: '',
            state: '',
            zip: '',
            waiverSignature: '',
          };

          addItem(registrationDataUpdate);

          if (
            user?.id &&
            experienceId &&
            typeDonationRefund.valid &&
            typeDonationRefund.type === 'Donation'
          ) {
            addItem({
              type: 'donation',
              donor: { id: user.id },
              makeAnonymous: false,
              allocation: { id: experienceId, type: 'experience' },
              price: Number(typeDonationRefund.price),
            });
          }

          if (typeDonationRefund.valid && typeDonationRefund.type === 'Refund') {
            /** @AlbertRoa */
          }

          closeModal();
        } else {
          closeModal();
        }
      }
    } catch (error) {
      console.error({ error });
      notification.error('registratio_options_update_error');
      closeModal();
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={closeModal} fullWidth>
      <Modal.Title>
        <Typography variant="h3" align="center" marginTop="15px">
          Change Registration
        </Typography>
        <Typography align="center" marginBottom="20px" marginTop="10px">
          {registrationOptionsData?.experience?.campaign?.name ?? 'N/A'}
        </Typography>
      </Modal.Title>
      {!loading && (
        <ItemAttendeesRegistrationEdit
          attendee={registrationOptionsData}
          setRegistrationDataUpdate={setRegistrationDataUpdate}
          setRefundOrDonate={setTypeDonationRefund}
        />
      )}
      <Modal.Actions>
        <Modal.Action type="secondary" content="Cancel" onAction={closeModal} />
        <Modal.Action
          type="primary"
          content="Update"
          onAction={onSubmit}
          disabled={loadingData}
          loading={loadingData}
        />
      </Modal.Actions>
    </Modal>
  );
};

export default AttendeesRegistrationEditModal;
