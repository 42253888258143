import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Grid, Box, Typography, Icon } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

import { RouterEntitiesId } from '@vizsla/constants';
import { getUserFullName, getUserAge, buildUrl } from '@vizsla/utils';
import { TableData, UserAvatar } from '@vizsla/components';
import type { TableColumn } from '@vizsla/components';

import type { Attendee } from 'src/types/attendees';
import { useAttendeesTableData } from 'src/hooks/context';
import * as appRoutes from 'src/constants/routes';

import { AttendeesSearchInput } from './components';

const AttendeesTable: React.FC = () => {
  const { attendees, isAttendeesLoading, pagination } = useAttendeesTableData();
  const { experienceId } = useParams();
  const navigate = useNavigate();
  const TABLE_COLUMNS: Array<TableColumn<Attendee>> = [
    {
      key: 'name',
      title: 'Name',
      render: (_v, attendee) => {
        return (
          <Grid container alignItems="center" gap={1}>
            <Grid item>
              <UserAvatar user={attendee.user} size="48px" />
            </Grid>
            <Grid item>
              <Typography variant="body1">{getUserFullName(attendee.user)}</Typography>
            </Grid>
          </Grid>
        );
      },
    },
    {
      key: 'status',
      title: 'Status',
      render: (_v, attendee) => {
        // TODO: Connect status with the attendee data field
        const status = false;
        return status ? (
          <CheckCircleIcon color="success" />
        ) : (
          <CheckCircleOutlineIcon color="disabled" />
        );
      },
    },
    {
      key: 'gender',
      title: 'Gender',
      render: (_v, attendee) => {
        return attendee.user?.gender || 'N/A';
      },
    },
    {
      key: 'age',
      title: 'Age',
      render: (_v, attendee) => {
        return getUserAge(attendee.user || null, 'year', 'N/A');
      },
    },
    {
      key: 'team',
      title: 'Team',
      render: (_v, attendee) => {
        return attendee.teamMembership?.team?.name || 'N/A';
      },
    },
    {
      key: 'bib_number',
      title: 'Bib Number',
      render: (_v, attendee) => {
        return attendee.bibAssignment?.bibNumber || 'N/A';
      },
    },
  ];

  const onRowClick = React.useCallback((attendee: Attendee) => {
    const url = buildUrl(appRoutes.checkinExperienceAttendeeCheckinPage, {
      [RouterEntitiesId.experience]: experienceId,
      attendeeId: attendee.id || '',
    });
    navigate(url);
    //
  }, []);

  return (
    <Box>
      <AttendeesSearchInput />
      <TableData
        dataSource={attendees}
        columns={TABLE_COLUMNS}
        loading={isAttendeesLoading}
        onRowClick={onRowClick}
        pagination={pagination}
      />
    </Box>
  );
};

export { AttendeesTable };
