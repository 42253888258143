import { gql } from '@apollo/client';

export const USER_INFO_UPDATE = gql`
  mutation User_Info_update($userid: ID, $data: UserUpdateInput!) {
    userUpdate(filter: { id: $userid }, data: $data) {
      firstName
      lastName
      gender
      birthDate
      phone {
        code
        number
      }
      address {
        city
        state
        country
        zip
      }
    }
  }
`;
