import React from 'react';
import { Box, Avatar, Typography } from '@mui/material';
import { Today as CalendarIcon } from '@mui/icons-material';

import { getStringifiedDateRange } from '@vizsla/utils';

import type { Campaign } from 'src/types/campaigns';
import { EMPTY_IMAGE } from 'src/assets';

interface CampaignBlockTitleProps {
  campaign: Campaign;
}

const CampaignBlockTitle: React.FC<CampaignBlockTitleProps> = ({ campaign }) => {
  const campaignLogoSrc = campaign.logo?.downloadUrl || EMPTY_IMAGE;

  return (
    <Box display="grid" gridTemplateColumns="110px 1fr" gap={1}>
      <Box>
        <Avatar
          sx={{ width: '100%', height: '60px' }}
          imgProps={{ sx: { objectFit: 'contain' } }}
          src={campaignLogoSrc}
          alt="campaign"
          variant="rounded"
        />
      </Box>

      <Box>
        <Typography variant="h4">{campaign.name}</Typography>

        <Box display="flex" alignItems="center" mt={0.8} gap={0.5}>
          <CalendarIcon />{' '}
          <Typography variant="subtitle1">
            {getStringifiedDateRange(campaign.startDate, campaign.endDate)}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export { CampaignBlockTitle };
