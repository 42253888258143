import React from 'react';
import { DateRangeOutlined as DateIcon } from '@mui/icons-material';

import { FiltersLayout } from '@vizsla/components';
import { DateFilterSettings } from '@vizsla/providers';

import { CampaignsList } from 'src/components/campaigns';

type CampaignCustomFilters = [DateFilterSettings];

const CAMPAIGN_CUSTOM_FILTERS: CampaignCustomFilters = [
  {
    type: 'date',
    filterName: 'Date',
    dateFilterKey: 'campaignDateFilter',
    fromToFields: ['startDate', 'endDate'],
    icon: <DateIcon />,
  },
];

const CheckinCampaignsPageLayout: React.FC = () => {
  return (
    <FiltersLayout withSearch customFilters={CAMPAIGN_CUSTOM_FILTERS}>
      <CampaignsList />
    </FiltersLayout>
  );
};

export { CheckinCampaignsPageLayout };
