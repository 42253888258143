import React from 'react';

import { AttendeeUpdateInput, useUpdateAttendeeWaiverSignatureMutation } from '@vizsla/graphql';

interface SignWaiverInput {
  attendeeId: string;
  waiverSignature: string;
  userId: string;
}
export function useSignWaiverMutation() {
  const [mutation, options] = useUpdateAttendeeWaiverSignatureMutation();

  const signWaiver = React.useCallback(
    async (input: SignWaiverInput) => {
      const data: AttendeeUpdateInput = {
        id: input.attendeeId,
        waiverSignature: input.waiverSignature,
        waiverSignedAt: new Date().toISOString(),
        waiverSignedBy: { connect: { id: input.userId } },
      };
      await mutation({ variables: { data } });
    },
    [mutation],
  );
  return [signWaiver, options] as const;
}
