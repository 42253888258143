import React from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { Form } from 'react-final-form';
import { Grid, Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { validateWithSchema, getUserRoles } from '@vizsla/utils';
import { useAppAuth, useCurrentUser, useNotification } from '@vizsla/hooks';
import { PaletteColor, Shade } from '@vizsla/theme';
import { SystemRoles } from '@vizsla/types';
import { LoginSchema } from '@vizsla/constants';
import { Button, Field, AuthTextField } from '@vizsla/components';

import * as appRoutes from 'src/constants/routes';
import { useAllowedRoutes } from 'src/hooks/authorization';
import { OrganizationAuthLayout } from 'src/layouts';

const useStyles = makeStyles((theme: Theme) => ({
  loginForm: {
    marginRight: 'auto',
    width: 440,
  },
  signupTextContainer: {
    marginTop: theme.spacing(4),
  },
  signupText: {
    color: Shade.Gray[400],
  },
  submitButton: {
    margin: theme.spacing(2.5, 0, 0, 0),
  },
}));

const AuthLoginPage: React.FC = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const notification = useNotification();

  const { isAuthenticated, login, logout } = useAppAuth();
  const { startRoutePath } = useAllowedRoutes();

  if (isAuthenticated) {
    navigate(startRoutePath);
  }

  const onSubmit = React.useCallback(
    async data => {
      try {
        const newUser = await login(data);
        const userRoles = getUserRoles(newUser);

        if (userRoles.system.includes(SystemRoles.Checkiner)) {
          return navigate(startRoutePath);
        }

        await logout();
        notification.error(`You don't have access to this app`);
        window.localStorage.clear();
        return navigate('/');
      } catch (e) {
        // todo: replace with toast message
        return {
          email: 'Incorrect email or password',
          password: 'Incorrect email or password',
        };
      }
    },
    [login, logout, navigate, notification, startRoutePath],
  );

  return (
    <OrganizationAuthLayout title="Welcome to Vizsla" caption="Login to your account">
      <Form onSubmit={onSubmit} validate={values => validateWithSchema(LoginSchema, values)}>
        {({ handleSubmit, submitting, pristine, hasValidationErrors }) => {
          const isDisabled = submitting || pristine || hasValidationErrors;

          return (
            <form onSubmit={handleSubmit} className={classes.loginForm}>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <Field
                    name="email"
                    label="Email Address"
                    component={AuthTextField}
                    e2e-id="email"
                  />
                </Grid>
                <Grid item xs={12}>
                  <Field
                    name="password"
                    label="Password"
                    component={AuthTextField}
                    type="password"
                    e2e-id="password"
                  />
                </Grid>
              </Grid>
              <Grid container justifyContent="left">
                <Grid item>
                  <Button
                    className={classes.submitButton}
                    type="submit"
                    color="secondary"
                    loading={submitting}
                    disabled={isDisabled}
                    e2e-id="submit"
                  >
                    Log In
                  </Button>
                </Grid>
              </Grid>
              <Grid container justifyContent="left" className={classes.signupTextContainer}>
                <Grid item>
                  <Typography className={classes.signupText} variant="caption">
                    Don&apos;t have an Account?&nbsp;
                  </Typography>
                  <Link
                    style={{ textDecoration: 'none', color: PaletteColor.TealText }}
                    to={appRoutes.authRegistration}
                  >
                    Sign Up Now
                  </Link>
                </Grid>
              </Grid>
            </form>
          );
        }}
      </Form>
    </OrganizationAuthLayout>
  );
};

export { AuthLoginPage };
