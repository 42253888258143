import { gql } from '@apollo/client';

import { FileFragmentFragmentDoc } from '@vizsla/graphql';

export const CHECKIN_ATTENDEE_FRAGMENT = gql`
  fragment CheckinAttendeeInfo on Attendee {
    id
    user {
      id
      firstName
      lastName
      gender
      birthDate
      avatar {
        ...FileFragment
      }
    }
    teamMembership {
      id
      role
      team {
        id
        name
      }
    }
    bibAssignment {
      id
      bibNumber
    }
  }
  ${FileFragmentFragmentDoc}
`;

export const CHECKIN_ATTENDEES_QUERY = gql`
  query CheckinAttendees($filter: AttendeeFilter, $skip: Int, $first: Int) {
    attendeesList(filter: $filter, sort: { createdAt: DESC }, skip: $skip, first: $first) {
      count
      items {
        ...CheckinAttendeeInfo
      }
    }
  }
  ${CHECKIN_ATTENDEE_FRAGMENT}
`;

export const USER_ATTENDEES = gql`
  query UserAttendees($id: ID) {
    attendee(id: $id) {
      user {
        id
        email
        avatar {
          id
          downloadUrl
          previewUrl
        }
        firstName
        lastName
        phone {
          number
        }
        gender
        birthDate
        address {
          city
          state
        }
        status
        createdAt
      }

      registrationOption {
        name
      }

      donations {
        items {
          amount
        }
      }
      fundraisingPageLink
      fundraiserGoal
      totalRaised

      bibAssignment {
        bibNumber
      }

      experience {
        id
        name
        waiverEnabled
        waiverTextBody
        store
        storeAssets {
          count
          items {
            price
          }
        }
      }
    }
  }
`;

export const ATTENDEE_REGISTARION_OPTIONS = gql`
  query AttendeesRegistrationOptions($id: ID) {
    attendee(id: $id) {
      id
      user {
        id
        email
        firstName
        lastName
        phone {
          number
        }
      }
      registrationOption {
        id
        name
        pricingSettings {
          pricingType
          pricingTiers {
            items {
              price
              taxDeductibleCurrencyAmount
              taxDeductiblePercentsAmount
              taxDeductibleType
            }
          }
        }
      }
      experience {
        id
        name
        campaign {
          id
          name
        }
        registrationOptions {
          items {
            id
            name
            fundraisingMinimum
            pricingSettings {
              pricingType
              pricingTiers {
                items {
                  price
                  taxDeductibleCurrencyAmount
                  taxDeductiblePercentsAmount
                  taxDeductibleType
                  startDate
                  endDate
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const UPDATE_REGISTRATION_OPTION = gql`
  mutation updateRegistrationOption($attenddeId: ID, $optionId: ID) {
    attendeeUpdate(
      filter: { id: $attenddeId }
      data: { registrationOption: { reconnect: { id: $optionId } } }
    ) {
      id
      registrationOption {
        id
        name
      }
    }
  }
`;

export const BIB_ASSIGNMENT_BY_ATTENDEE = gql`
  mutation BibAssignmentByAttendee($experienceId: ID!, $attendeeId: ID!) {
    bibAssignByAttendee(experienceId: $experienceId, attendeeId: $attendeeId) {
      bibNumber
    }
  }
`;

export const ATTENDEE_CHECKED = gql`
  mutation AttendeeChecked($attendeeId: ID!, $checked: Boolean!) {
    attendeeUpdate(filter: { id: $attendeeId }, data: { checkIn: $checked }) {
      id
      checkIn
      user {
        id
        firstName
      }
    }
  }
`;

export const ATTENDEE_INDIVIDUAL_FUNDRAISING = gql`
  query AttendeeIndividualFundraising($filter: AttendeeFilter!) {
    attendeesList(filter: $filter) {
      items {
        id
        fundraising {
          id
          title
        }
        user {
          id
          firstName
          lastName
          email
        }
      }
    }
  }
`;

export const ATTENDEE_SEND_EMAIL_MUTATION = gql`
  mutation attendeSendEmail($notificationData: JSON, $notificationMethods: [String!]) {
    notifications {
      notificationSend(
        notificationData: $notificationData
        notificationMethods: $notificationMethods
      ) {
        success
      }
    }
  }
`;

export const GET_SWAGBAG_AASETS_OPTION_DOCUMENT = gql`
  query getSwagBagAssetsByOption($filter: AssetByRegistrationOptionViewFilter!) {
    assetByRegistrationOptionViewsList(filter: $filter) {
      items {
        available
        continueSalesWhenOutOfStock
        id
        inventoryId
        name
        picture
        registrationOptionAId
        storeAvailability
        itemToSellWhenOutOfStock
        variantsValues
      }
    }
  }
`;

export const UPDATE_ATTENDEE_WAIVER_SIGNATURE = gql`
  mutation updateAttendeeWaiverSignature($data: AttendeeUpdateInput!) {
    attendeeUpdate(data: $data) {
      id
      waiverSignedAt
      waiverSignature
    }
  }
`;
