import { RouterEntitiesId as entitiesId } from '@vizsla/constants';

// Auth routes
export const auth = '/auth';
export const authLogin = '/auth/login';
export const authRegistration = '/auth/register';
export const authChangePassword = '/auth/change-password';
export const authConfirmationEmail = '/auth/confirm';
export const authCompleteRegistration = '/auth/complete';

// Checkin routes
export const checkin = '/checkin';
export const checkinExperienceAttendeeCheckinPage = `/checkin/:${entitiesId.experience}/attendees/:attendeeId`;
export const checkinExperienceAttendeesPage = `/checkin/:${entitiesId.experience}/attendees`;
export const checkinExperiencePage = `/checkin/:${entitiesId.experience}`;
export const checkinExperiencePageCheckout = `/checkin/:${entitiesId.experience}/checkout/:parentOrderId`;
export const checkinExperiencePagePayment = `/checkin/:${entitiesId.experience}/payment`;
