/* eslint-disable prefer-template */
import {
  Button,
  DialogActions,
  Grid,
  gridClasses,
  Stack,
  Typography,
  Box,
  CircularProgress,
  Input,
  TextField as TField,
} from '@mui/material';
import { Field, Form } from 'react-final-form';
import React, { useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { LoadingButton } from '@mui/lab';
import moment from 'moment';
import { useParams } from 'react-router-dom';

import { NumberField, SelectField, DateField, UserAvatar, TextField } from '@vizsla/components';
import { MODALS, USA_STATES_OPTIONS, GENDER_OPTIONS, DateFormatPatterns } from '@vizsla/constants';
import { validateWithSchema } from '@vizsla/utils';
import {
  User,
  UserUpdateInput,
  useUsersListLazyQuery,
  useCurrentAttendeeQuery,
} from '@vizsla/graphql';
import { useModal, useNotification } from '@vizsla/hooks';

import { USER_INFO_UPDATE } from 'src/graphql/users';

import { editUsersInfoSchema } from '../../constants/validationSchemas/editUserInfoSchema';

const AttendeesEditUserInfoContent = () => {
  const { attendeeId } = useParams();
  const { isOpen, closeModal } = useModal(MODALS.ATTENDEE_EDIT_USERINFO_MODAL);
  const { data, loading: loadingCurrentAttendee } = useCurrentAttendeeQuery({
    variables: { attendeeid: attendeeId },
  });
  const currentAttendee = data?.attendee?.user;
  const idAttendee = data?.attendee?.user?.id;
  const notification = useNotification();
  const dateCreatedAt = moment(data?.attendee?.user?.createdAt).format('L');
  const birthDateAttendee = moment(data?.attendee?.user?.birthDate).format('YYYY-MM-DD');
  const [userUpdate, { loading: loadingUserEditInfo }] = useMutation<UserUpdateInput>(
    USER_INFO_UPDATE,
    {
      refetchQueries: ['currentAttendee'],
      awaitRefetchQueries: true,
      variables: {
        userid: data?.attendee?.user?.id,
      },
    },
  );

  const INITIAL_VALUES = {
    firstName: data?.attendee?.user?.firstName,
    lastName: data?.attendee?.user?.lastName,
    email: data?.attendee?.user?.email,
    gender: data?.attendee?.user?.gender,
    phone: {
      number: data?.attendee?.user?.phone?.number,
      code: data?.attendee?.user?.phone?.code,
    },
    birthDate: moment(data?.attendee?.user?.birthDate).format('L'),
    address: {
      street1: data?.attendee?.user?.address?.street1,
      city: data?.attendee?.user?.address?.city,
      state: data?.attendee?.user?.address?.state,
      country: data?.attendee?.user?.address?.country,
      zip: data?.attendee?.user?.address?.zip,
    },
  };
  const onSubmit = async (data: UserUpdateInput) => {
    try {
      await userUpdate({
        variables: {
          userid: idAttendee,
          data: {
            firstName: data?.firstName,
            lastName: data?.lastName,
            email: data?.email,
            gender: data?.gender,
            phone: {
              number: data?.phone?.number,
              code: data?.phone?.code,
            },
            birthDate: moment(data?.birthDate).format('YYYY-MM-DD'),
            address: {
              street1: data?.address?.street1,
              city: data?.address?.city,
              state: data?.address?.state,
              country: data?.address?.country,
              zip: data?.address?.zip,
            },
          },
        },
      });
      notification.success('User update success');
      setTimeout(() => {
        closeModal();
      }, 2000);
    } catch (error) {
      notification.error('Error updating user');
      console.error(error);
    }
  };

  if (loadingCurrentAttendee || loadingUserEditInfo) {
    return <CircularProgress color="primary" />;
  }
  return (
    <Grid container spacing={2}>
      <Grid>
        <Grid
          item
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
          marginTop="8px"
        >
          <Box sx={{ display: 'flex' }}>
            <UserAvatar size="63.45px" user={currentAttendee} />
          </Box>
          <Grid item>
            <Typography
              sx={{
                height: '29px',
                width: '400px',
                color: '#202B38',
                fontFamily: 'Inter',
                fontSize: '24px',
                fontWeight: 'bold',
                letterSpacing: '-0.2px',
                lineHeight: '29px',
                textAlign: 'center',
              }}
            >
              {data?.attendee?.user?.firstName + ' ' + data?.attendee?.user?.lastName}
            </Typography>
          </Grid>
          <Grid item>
            <Typography
              sx={{
                height: '20px',
                width: '150px',
                color: '#5D6975',
                fontFamily: 'Inter',
                fontSize: '14px',
                letterSpacing: '-0.2px',
                lineHeight: '20px',
                textAlign: 'center',
                marginBottom: '40px',
              }}
            >
              Member Since {dateCreatedAt.slice(6)}
            </Typography>
          </Grid>
        </Grid>
        <Form
          onSubmit={onSubmit}
          initialValues={INITIAL_VALUES}
          validate={values => validateWithSchema(editUsersInfoSchema, values)}
        >
          {({ handleSubmit, submitting, pristine, hasValidationErrors }) => {
            const isSubmitDisabled = submitting || pristine || hasValidationErrors;
            return (
              <form onSubmit={handleSubmit}>
                <Grid container>
                  <Grid item>
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <Field
                          fullWidth
                          name="firstName"
                          label="First Name"
                          component={TextField}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <Field
                          fullWidth
                          name="lastName"
                          label="Last Name"
                          required
                          component={TextField}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <Field
                          fullWidth
                          name="email"
                          label="Email"
                          required
                          disabled
                          component={TextField}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <Field
                          fullWidth
                          name="phone.number"
                          label="Phone"
                          formatMask="+1 (###) ###-####"
                          isNumericString
                          required
                          component={NumberField}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <Field name="birthDate" fullWidth>
                          {props => (
                            <div>
                              <TField
                                label="Birthdate"
                                name={props.input.name}
                                defaultValue={birthDateAttendee}
                                onChange={props.input.onChange}
                                type="date"
                                fullWidth
                                required
                              />
                            </div>
                          )}
                        </Field>
                      </Grid>
                      <Grid item xs={6}>
                        <Field
                          fullWidth
                          name="gender"
                          label="Gender"
                          requierd
                          component={SelectField}
                          options={GENDER_OPTIONS}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Typography variant="subtitle2">Address</Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Field
                          fullWidth
                          name="address.street1"
                          label="Address"
                          component={TextField}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <Field fullWidth name="address.city" label="City" component={TextField} />
                      </Grid>
                      <Grid item xs={6}>
                        <Field
                          fullWidth
                          name="address.state"
                          label="State/Province"
                          component={SelectField}
                          options={USA_STATES_OPTIONS}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <Field
                          fullWidth
                          name="address.country"
                          label="Country"
                          component={TextField}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <Field
                          fullWidth
                          name="address.zip"
                          label="Zip Code"
                          required
                          isNumericString
                          formatMask="#####"
                          component={NumberField}
                        />
                      </Grid>
                      <Grid
                        item
                        xs={6}
                        sx={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          margin: 'auto',
                          marginTop: '20px',
                        }}
                      >
                        <Button
                          color="secondary"
                          variant="outlined"
                          onClick={() => closeModal()}
                          sx={{
                            height: '36px',
                            width: '88px',
                            marginLeft: '8px',
                          }}
                        >
                          Cancel
                        </Button>
                        <LoadingButton
                          color="primary"
                          onClick={handleSubmit}
                          disabled={isSubmitDisabled}
                          loading={loadingUserEditInfo || loadingCurrentAttendee}
                          sx={{
                            height: '36px',
                            width: '88px',
                          }}
                        >
                          Update
                        </LoadingButton>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </form>
            );
          }}
        </Form>
      </Grid>
    </Grid>
  );
};

export { AttendeesEditUserInfoContent };
