import React from 'react';
import { Navigate } from 'react-router-dom';
import _ from 'lodash';

import { useExperienceId, useExperienceById } from '@vizsla/hooks';
import { FullscreenLoader } from '@vizsla/components';

import * as appRoutes from 'src/constants/routes';
import { ExperienceAttendeesPageLayout } from 'src/layouts';

const ExperienceAttendeesPage: React.FC = () => {
  const experienceId = useExperienceId();
  const { experience, loading } = useExperienceById(experienceId);

  if (loading) {
    return <FullscreenLoader variant="light" />;
  }

  // TODO: notification if experience not found

  if (_.isNil(experience)) {
    return <Navigate to={appRoutes.checkin} />;
  }

  return <ExperienceAttendeesPageLayout />;
};

export { ExperienceAttendeesPage };
