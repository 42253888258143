import { createContext } from 'react';

import type { PaginationContextData } from '@vizsla/providers';

import type { Attendee } from 'src/types/attendees';

interface AttendeesTableDataContextType {
  attendees: Attendee[];
  isAttendeesLoading: boolean;
  setSearchValue: (value: string | undefined) => void;
  pagination: PaginationContextData;
}

export const AttendeesTableDataContext = createContext({} as AttendeesTableDataContextType);
