import React from 'react';

import { useCheckinExperiencesQuery } from '@vizsla/graphql';

import { ExperiencesCards, ExperiencesTable } from './components';

interface CampaignExperiencesInfoProps {
  campaignId: string;
  isActive?: boolean;
}

const CampaignExperiencesInfo: React.FC<CampaignExperiencesInfoProps> = ({
  campaignId,
  isActive = true,
}) => {
  const { data, loading } = useCheckinExperiencesQuery({
    variables: {
      filter: {
        campaign: {
          id: {
            equals: campaignId,
          },
        },
      },
    },
    skip: !isActive,
  });

  const experiences = data?.experiencesList.items || [];

  return (
    <React.Fragment>
      <ExperiencesCards experiences={experiences} isLoading={loading} />
      <ExperiencesTable experiences={experiences} isLoading={loading} />
    </React.Fragment>
  );
};

export { CampaignExperiencesInfo };
