import React from 'react';
import { isEmpty } from 'lodash';
import { Grid, Typography } from '@mui/material';
import { Field, Form } from 'react-final-form';

import { useModal } from '@vizsla/hooks';
import { Button, HtmlRenderer, Modal, TextField } from '@vizsla/components';
import { formatDate } from '@vizsla/utils';
import { DateFormatPatterns } from '@vizsla/constants';

import { MODALS } from 'src/constants/modals';

import { WaiverSignatureContainer, WaiverSignatureItem } from './AttendeeWaiverModal.styles';

export interface AttendeeWaiverModalFormValues {
  signature: string;
}

type AttendeeWaiverModalProps = {
  waiverBody: string;
  signature?: string;
  signedAt?: string;
  onSubmit: (value: AttendeeWaiverModalFormValues) => Promise<void>;
};
export const AttendeeWaiverModal = ({
  waiverBody,
  signature,
  signedAt,
  onSubmit,
}: AttendeeWaiverModalProps) => {
  const { isOpen, closeModal } = useModal(MODALS.ATTENDEES_WAIVER);
  const { haveSignature, formattedSignedAt } = React.useMemo(
    () => ({
      haveSignature: !isEmpty(signature),
      formattedSignedAt: formatDate(signedAt, DateFormatPatterns.fullDateWithTime),
    }),
    [signature, signedAt],
  );
  const initialValues: AttendeeWaiverModalFormValues = React.useMemo(
    () => ({ signature: signature ?? '' }),
    [signature],
  );
  const handleSubmit = React.useCallback(
    async (values: AttendeeWaiverModalFormValues) => {
      closeModal();
      await onSubmit(values);
    },
    [closeModal, onSubmit],
  );

  return (
    <Modal isOpen={isOpen} onClose={closeModal} fullWidth>
      <Typography variant="h3" align="center" marginTop="15px">
        Waiver
      </Typography>
      <Grid maxHeight="423px" width="80%" alignSelf="center" overflow="auto" mt="32px">
        <HtmlRenderer html={waiverBody} />
      </Grid>
      {!haveSignature && (
        <Form initialValues={initialValues} onSubmit={handleSubmit}>
          {({ handleSubmit }) => (
            <Grid container width="80%" alignSelf="center">
              <Grid item xs={12} mt="23px">
                <Field fullWidth name="signature" label="Name" component={TextField} />
              </Grid>
              <Grid item mt="23px" xs={12} display="flex" justifyContent="center">
                <Button onClick={handleSubmit}>Sign</Button>
              </Grid>
            </Grid>
          )}
        </Form>
      )}
      {haveSignature && (
        <WaiverSignatureContainer>
          <WaiverSignatureItem>
            <Typography variant="subtitle2">Signed at</Typography>
            <Typography variant="body1">{formattedSignedAt}</Typography>
          </WaiverSignatureItem>
          <WaiverSignatureItem>
            <Typography variant="subtitle2">Signature</Typography>
            <Typography variant="body1">{signature}</Typography>
          </WaiverSignatureItem>
        </WaiverSignatureContainer>
      )}
    </Modal>
  );
};
