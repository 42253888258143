import React from 'react';
import { Popover } from '@mui/material';
import { ShoppingCart as ShoppingCartIcon } from '@mui/icons-material';

import { Button, ShoppingCart } from '@vizsla/components';
import { usePopover, useShoppingCart } from '@vizsla/hooks';

import { Container, Content, PopoverContent } from './AttendeeCheckinCart.style';

interface AttendeeCheckinCartProps {
  onCheckout?(): void;
  loading?: boolean;
}

export function AttendeeCheckinCart(props: AttendeeCheckinCartProps) {
  const { items: cart } = useShoppingCart();

  const popover = usePopover();

  const count = React.useMemo(() => cart.length, [cart]);

  const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    popover.openPopover(event);
  };

  const handleClose = () => {
    popover.closePopover();
  };

  return (
    <Container>
      <Button
        startIcon={<ShoppingCartIcon />}
        onClick={handleOpen}
        disabled={count === 0}
        color="error"
      >
        Cart ({count})
      </Button>
      <Popover
        open={popover.isOpen}
        anchorEl={popover.el}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
      >
        <PopoverContent>
          <ShoppingCart />

          <Content>
            <Button disabled={count === 0} onClick={props.onCheckout} loading={props.loading}>
              Checkout
            </Button>
          </Content>
        </PopoverContent>
      </Popover>
    </Container>
  );
}
