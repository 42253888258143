/* eslint-disable react/react-in-jsx-scope -- Unaware of jsxImportSource */
/** @jsxImportSource @emotion/react */
import React from 'react';
import { css, jsx } from '@emotion/react';
import {
  Card,
  CardContent,
  Grid,
  RadioGroup,
  Radio,
  FormControlLabel,
  Theme,
  Typography,
} from '@mui/material';

const gridItems = css`
  display: grid;
  justify-content: center;
`;

export const DonationOrRefund = ({ typeDonationRefund, setTypeDonationRefund }) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setTypeDonationRefund({ ...typeDonationRefund, type: value, valid: true });
  };
  return (
    <Grid>
      <Typography variant="h4" align="center" marginTop="15px">
        what do you want to do with the remaining money?
      </Typography>
      <Grid css={gridItems}>
        <RadioGroup defaultValue="Donation" row onChange={handleChange}>
          <FormControlLabel label="Donate" control={<Radio />} value="Donation" />
          <FormControlLabel label="Refund" control={<Radio />} value="Refund" />
        </RadioGroup>
      </Grid>
    </Grid>
  );
};
