import React from 'react';
import { Typography, Grid } from '@mui/material';
import { useMutation } from '@apollo/client';

import { useModal, useNotification } from '@vizsla/hooks';
import { Modal } from '@vizsla/components';
import { formatDate, formatMoney } from '@vizsla/utils';
import { DateFormatPatterns } from '@vizsla/constants';

import { MODALS } from 'src/constants/modals';
import { ATTENDEE_SEND_EMAIL_MUTATION } from 'src/graphql/attendees';

import { RegistrationReceptionTextItem } from './components/RegistrationReceptionTextItem';

export const AteendeesRegistrationReceiptModal = ({
  id,
  date,
  status,
  amount,
  description,
  userName,
  email,
}) => {
  const notification = useNotification();

  const formatId = React.useMemo(() => id.toString() || 'N/A', [id]);

  const formateDate = React.useMemo(
    () => formatDate(date, DateFormatPatterns.shortDateWithSlash) || 'N/A',
    [date],
  );

  const formatTime = React.useMemo(
    () => formatDate(date, DateFormatPatterns.meridiemTimePadded) || 'N/A',
    [date],
  );

  const formatAmount = React.useMemo(() => formatMoney(amount) || 'N/A', [amount]);

  const { isOpen, closeModal } = useModal(MODALS.ATTENDEES_REGISTRATION_RECEIPT);
  const [isLoading, setIsLoading] = React.useState(false);
  const [mutation] = useMutation(ATTENDEE_SEND_EMAIL_MUTATION);

  const onSubmit = async () => {
    setIsLoading(true);
    try {
      await mutation({
        variables: {
          notificationData: {
            to: email,
            from: 'noreply@vizsla.com',
            templateId: 'd-0493aa7ac28a43c5bc0f9ba6067ff155',
            dynamic_template_data: {
              id,
              date: formateDate,
              time: formatTime,
              status,
              amount: formatAmount,
              description,
              userName,
            },
          },
          notificationMethods: ['email'],
        },
      });
      notification.success('Email sent');
      closeModal();
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      notification.error('Error sending mail');
      setIsLoading(false);
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={closeModal} fullWidth>
      <Grid container width="90%" justifyContent="center" display="flex" alignSelf="center">
        <Typography variant="h3" align="center" marginTop={2} alignSelf="center">
          Registration Receipt
        </Typography>
        <RegistrationReceptionTextItem textTitle="ID:" textBody={formatId} isfirst />
        <RegistrationReceptionTextItem textTitle="Date:" textBody={formateDate} />
        <RegistrationReceptionTextItem textTitle="Time:" textBody={formatTime} />
        <RegistrationReceptionTextItem textTitle="Status:" textBody={status} isStatus />
        <RegistrationReceptionTextItem textTitle="Type:" textBody="Registration Option" />
        <RegistrationReceptionTextItem textTitle="Amount:" textBody={formatAmount} />
        <RegistrationReceptionTextItem textTitle="Description:" textBody={description} />
        <Grid xs={12}>
          <Typography mt={4} fontWeight="bold">
            Paymeny Method
          </Typography>
        </Grid>
        <RegistrationReceptionTextItem textTitle="Type:" textBody="N/A" isfirst />
        <RegistrationReceptionTextItem textTitle="Bank:" textBody="N/A" />
        <RegistrationReceptionTextItem textTitle="Card No.:" textBody="N/A" />

        <Grid mt={5}>
          <Modal.Action type="primary" content="Resend" onAction={onSubmit} loading={isLoading} />
        </Grid>
      </Grid>
    </Modal>
  );
};
