import React from 'react';
import { Skeleton, Box, Typography, styled } from '@mui/material';

import { useCheckinCampaignsQuery } from '@vizsla/graphql';
import {
  useCurrentOrganization,
  useSearchQueryVariables,
  useCustomFiltersQueryVariables,
} from '@vizsla/hooks';
import { createSimpleRange } from '@vizsla/utils';
import { CampaignStatus } from '@vizsla/types';

import { CampaignBlock } from './components';

const StyledSkeleton = styled(Skeleton)<{ keyIndex: number }>(({ theme, keyIndex }) => ({
  opacity: (0.1 / keyIndex) * 10,
  '&:not(:first-child)': {
    marginTop: theme.spacing(3),
  },
}));

const SKELETONS_RANGE = createSimpleRange(5);

const CampaignsList: React.FC = () => {
  const [currentCampaignId, setCurrentCampaignId] = React.useState<string>();

  const { organizationId, organizationIsLoading } = useCurrentOrganization();
  const searchQueryVariablesByCampaignName = useSearchQueryVariables('name');
  const customFilterQueryVariables = useCustomFiltersQueryVariables();

  const { data, loading: isCampaignsLoading } = useCheckinCampaignsQuery({
    variables: {
      filter: {
        vizslaOrganization: {
          id: {
            equals: organizationId,
          },
        },
        campaignStatus: {
          equals: CampaignStatus.live,
        },
        ...searchQueryVariablesByCampaignName,
        ...customFilterQueryVariables,
      },
    },
    skip: !organizationId,
  });

  const handleCampaignOpen = React.useCallback((campaignId?: string | null) => {
    setCurrentCampaignId(campaignId || undefined);
  }, []);

  const campaigns = data?.campaignsList.items || [];
  const isLoading = organizationIsLoading || isCampaignsLoading;

  if (isLoading) {
    return (
      <React.Fragment>
        {SKELETONS_RANGE.map(index => (
          <StyledSkeleton
            key={index}
            keyIndex={index}
            variant="rectangular"
            animation="wave"
            height="120px"
          />
        ))}
      </React.Fragment>
    );
  }

  if (campaigns.length === 0) {
    return (
      <Box padding={2}>
        <Typography variant="body1" textAlign="center">
          No active campaigns yet
        </Typography>
      </Box>
    );
  }

  return (
    <React.Fragment>
      {campaigns.map(campaignInfo => (
        <CampaignBlock
          key={campaignInfo.id}
          campaign={campaignInfo}
          opened={currentCampaignId === campaignInfo.id}
          handleOpen={() => handleCampaignOpen(campaignInfo.id)}
        />
      ))}
    </React.Fragment>
  );
};

export { CampaignsList };
