import React from 'react';
import { Navigate } from 'react-router-dom';
import _ from 'lodash';

import { RouterEntitiesId } from '@vizsla/constants';
import { buildUrl } from '@vizsla/utils';
import { useExperienceId, useExperienceById } from '@vizsla/hooks';
import { FullscreenLoader } from '@vizsla/components';

import * as appRoutes from 'src/constants/routes';

const ExperiencePageRoot: React.FC = () => {
  const experienceId = useExperienceId();
  const { experience, loading } = useExperienceById(experienceId);

  if (loading) {
    return <FullscreenLoader variant="light" />;
  }

  // TODO: notification if experience not found

  if (_.isNil(experience)) {
    return <Navigate to={appRoutes.checkin} />;
  }

  return (
    <Navigate
      to={buildUrl(appRoutes.checkinExperienceAttendeesPage, {
        [RouterEntitiesId.experience]: experienceId,
      })}
    />
  );
};

export { ExperiencePageRoot };
