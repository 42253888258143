import styled from '@emotion/styled';

export const WaiverSignatureContainer = styled('div')`
  width: 80%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  padding-top: ${({ theme }) => theme.spacing(1)};
  margin: ${({ theme }) => theme.spacing(1)} auto 0;
  border-top: 1px solid ${({ theme }) => theme.palette.grey[300]};
`;

export const WaiverSignatureItem = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
