import React, { useEffect, useMemo, useState } from 'react';
import { Navigate, Route, Routes, useNavigate, useParams } from 'react-router-dom';
import { Box, Checkbox, CircularProgress, Grid, Typography, TypographyProps } from '@mui/material';
import { styled } from '@mui/material/styles';
import {
  ArrowBackRounded,
  Article,
  CheckCircleOutline,
  ContactPage,
  Error,
  LocalMall,
  PriceCheck,
  Receipt,
} from '@mui/icons-material';
import { isNil } from 'lodash';
import { useMutation, useQuery } from '@apollo/client';

import {
  Button,
  type ButtonProps,
  PeopleInformationItem,
  UserInformationCard,
} from '@vizsla/components';
import { buildUrl } from '@vizsla/utils';
import { useCurrentUser, useModal, useNotification, useShoppingCart } from '@vizsla/hooks';
import { useBibAssignmentByAttendeeMutation, useUserAttendeesQuery } from '@vizsla/graphql';

import {
  AteendeesRegistrationReceiptModal,
  AttendeeCheckin,
  AttendeesDonation,
  AttendeesRegistrationEditModal,
  AttendeeStore,
  AttendeeSwagBagModal,
  AttendeeWaiverModal,
} from 'src/modals';
import { AttendeesEdituserInfoModal } from 'src/components/attendees/AttendeesEditUserInfo';
import { MODALS } from 'src/constants/modals';
import { useUserOrders } from 'src/hooks/userAttendees/useUserOrders';
import { ATTENDEE_CHECKED, ATTENDEE_INDIVIDUAL_FUNDRAISING } from 'src/graphql/attendees';
import * as appRoutes from 'src/constants/routes';

import { AttendeeCheckinCart } from './AttendeeCheckinCart';
import { AttendeeWaiverModalFormValues } from '../../../modals/attendee/AttendeeWaiverModal/AttendeeWaiverModal';
import { useSignWaiverMutation } from '../../../hooks';

interface WaiverObject {
  alert: {
    title: string;
    subtitle: null;
  };
  waiver: boolean | null;
  override: boolean;
}

interface Swagbag {
  // TODO: Add ExperienceStoreAsset type
  items: Array<any> | null;
}

interface Purchases {
  // TODO: Add ExperienceStoreAsset type
  store: Array<any> | null;
}

const AttendeeCheckinPageLayout: React.FC = () => {
  const { user } = useCurrentUser();
  const notification = useNotification();
  const { attendeeId, experienceId } = useParams();
  const { items: itemsShoppingCart } = useShoppingCart();
  const [loadingCheckout, setLoadingCheckout] = useState(false);

  const {
    data: infoAttendee,
    userData,
    refetchAttendeeData,
    loading: loadingInfoAttendee,
  } = useUserOrders(attendeeId, experienceId);
  // TODO: chance data For useUserOrders
  const { data, loading } = useUserAttendeesQuery({
    variables: {
      id: attendeeId,
    },
  });

  const [signWaiverMutation, waiverMutationOptions] = useSignWaiverMutation();

  const onWaiverSign = React.useCallback(
    async (values: AttendeeWaiverModalFormValues) => {
      if (!attendeeId || !user?.id) return;
      await signWaiverMutation({ attendeeId, userId: user.id, waiverSignature: values.signature });
      await refetchAttendeeData();
    },
    [attendeeId, refetchAttendeeData, signWaiverMutation, user.id],
  );

  const navigate = useNavigate();

  const [checkIn, setCheckIn] = useState(false);

  const [registration, setRegistration] = useState({
    registrationOption: '',
    registraationOptions: [],
  });

  const [fundraising, setFundraising] = useState({
    alert: {
      title: '',
      subtitle: '',
    },
    override: false,
    fundraising: false,
  });

  const [bibNumber, setBibNumber] = useState({
    alert: {
      title: 'Not Assigned',
      subtitle: null,
    },
    bibNumber: -1,
  });

  const [waiver, setWaiver] = useState<WaiverObject>({
    alert: {
      title: 'Waiver is required',
      subtitle: null,
    },
    waiver: null,
    override: false,
  });

  const [swagBag, setSwagBag] = useState<Swagbag>({
    items: null,
  });

  const [purchases, setPurchases] = useState<Purchases>({
    store: null,
  });

  const [regOptionId, setRegOptionId] = useState('');
  const orderId = useMemo(
    () => infoAttendee?.attendee?.registrationOptionFulfillment?.items[0]?.order?.id,
    [infoAttendee],
  );
  useEffect(() => {
    if (bibNumber.bibNumber > 0 && itemsShoppingCart.length === 0) {
      setCheckIn(true);
    } else {
      setCheckIn(false);
    }
  }, [waiver.waiver, bibNumber.bibNumber, fundraising.fundraising, itemsShoppingCart]);

  const { openModal } = useModal();
  // mutation attendeChecked
  const [attendeChecked] = useMutation(ATTENDEE_CHECKED);
  const { data: dataAttendee } = useQuery(ATTENDEE_INDIVIDUAL_FUNDRAISING, {
    variables: {
      filter: {
        AND: [
          {
            id: {
              equals: attendeeId,
            },
          },
          {
            experience: {
              id: {
                equals: experienceId,
              },
            },
          },
        ],
      },
    },
  });
  useMemo(() => {
    setSwagBag({ ...swagBag, items: infoAttendee?.attendee?.assetSwagbag?.items || [] });
  }, [infoAttendee]);
  const [bibAssignmentByAttendeeMutation, { loading: loadingBib }] =
    useBibAssignmentByAttendeeMutation();
  if (!loading && data) {
    if (data?.attendee === null) {
      return (
        <Routes>
          <Route path="*" element={<Navigate to={`/checkin/${experienceId}/attendees/`} />} />
        </Routes>
      );
    }

    if (registration.registrationOption === '') {
      setRegistration({
        ...registration,
        registrationOption: data?.attendee?.registrationOption?.name || 'null',
      });
    }

    if (fundraising.alert.title === '') {
      setFundraising({
        ...fundraising,
        alert: {
          title: `Remaining: $${
            (infoAttendee?.attendee?.fundraiserGoal || 0) -
            (infoAttendee?.attendee?.totalRaised || 0)
          }`,
          subtitle: `Raised $${data?.attendee?.totalRaised || 0} of $${
            infoAttendee?.attendee?.fundraiserGoal || 0
          } minimum commitmment`,
        },
      });
    }

    if (bibNumber.bibNumber === -1) {
      setBibNumber({
        ...bibNumber,
        bibNumber: infoAttendee?.attendee?.bibAssignment?.bibNumber || 0,
      });
    }

    if (waiver.waiver === null) {
      setWaiver({ ...waiver, waiver: infoAttendee?.attendee?.experience?.waiverEnabled || false });
    }

    if (purchases.store === null) {
      setPurchases({ ...purchases, store: infoAttendee?.attendee?.assetFulfillment?.items || [] });
    }
  }

  if (!loadingInfoAttendee && infoAttendee) {
    if (regOptionId === '') {
      setRegOptionId(infoAttendee?.attendee?.registrationOption?.id || '');
    }
  }
  const StyledButton = styled(Button)<ButtonProps>(({ theme }) => ({
    height: '36px',
    width: '175px',
    borderRadius: '8px',
    backgroundColor: '#009FB0',
    color: '#FFFFFF',
    fontSize: '4px',
    fontWeight: 'bold',
    borderColor: '#009FB0',
    letterSpacing: 0,
    fontFamily: 'Inter',
    '&:hover': {
      backgroundColor: '#009FB0',
      color: '#FFFFFF',
    },
  }));

  const OthersStyledTittle = styled(Typography)<TypographyProps>(({ theme }) => ({
    height: '40px',
    width: '255px',
    color: '#1F2B38',
    fontFamily: 'inter',
    fontSize: '12px',
    letterSpacing: '-0.2px',
    lineHeight: '16px',
    fontWeight: '600',
    paddingTop: '5px',
    paddingRight: '10px',
    paddingBottom: '5px',
    borderBottomStyle: 'solid',
    borderBottomWidth: '2px',
    borderBottomColor: '#e7e9eb',
  }));

  const CardTitleStyled = styled(Typography)<TypographyProps>(({ theme }) => ({
    height: '21px',
    width: '220px',
    color: '#202B38',
    fontFamily: 'inter',
    fontSize: '18px',
    letterSpacing: '-0.2px',
    lineHeight: '21px',
    fontWeight: '900',
    marginTop: '38px',
  }));
  const AttentionTitleStyle = styled(Typography)<TypographyProps>(({ theme }) => ({
    height: '22px',
    width: '167px',
    color: '#DF3B3B',
    fontFamily: 'inter',
    fontSize: '18px',
    letterSpacing: '-0.23px',
    lineHeight: '22px',
    marginLeft: '6px',
  }));
  const AttentionSubTitleStyle = styled(Typography)<TypographyProps>(({ theme }) => ({
    height: '20px',
    width: '315px',
    color: '#5D6975',
    fontFamily: 'inter',
    fontSize: '14px',
    letterSpacing: '-0.2px',
    lineHeight: '20px',
  }));

  const CardTextStyled = styled(Typography)<TypographyProps>(({ theme }) => ({
    height: '22px',
    width: '250px',
    color: '#202B38',
    fontFamily: 'inter',
    fontSize: '18px',
    letterSpacing: '-0.23px',
    lineHeight: '22px',
    marginBottom: '40px',
    marginLeft: '32px',
  }));
  const OverrideTextStyled = styled(Typography)<TypographyProps>(({ theme }) => ({
    height: '20px',
    width: '56px',
    color: '#202B38',
    fontFamily: 'inter',
    fontSize: '14px',
    letterSpacing: '-0.2px',
    lineHeight: '20px',
    marginLeft: '1px',
    marginTop: '10px',
  }));
  const SimpleStyledButton = styled(Button)<ButtonProps>(({ theme }) => ({
    height: '36px',
    minWidth: '120px',
    maxWidth: '120px',
    borderRadius: '8px',
    backgroundColor: '#D9F5F9',
    color: '#007E8C',
    fontSize: '14px',
    fontWeight: 'bold',
    borderColor: '#009FB0',
    letterSpacing: 0,
    fontFamily: 'Inter',
    lineHeight: '20px',
    textAlign: 'center',
    '&:hover': {
      backgroundColor: '#D9F5F9',
      color: '#007E8C',
    },
  }));
  const AttentionStyledButton = styled(Button)<ButtonProps>(({ theme }) => ({
    height: '36px',
    minWidth: '96px',
    borderRadius: '8px',
    backgroundColor: '#CF362C',
    color: '#FFFFFF',
    fontSize: '14px',
    fontWeight: 'bold',
    letterSpacing: 0,
    fontFamily: 'Inter',
    lineHeight: '20px',
    textAlign: 'center',
    '&:hover': {
      backgroundColor: '#CF362C',
      color: '#FFFFFF',
    },
  }));

  const DisableButton = styled(Button)<ButtonProps>(({ theme }) => ({
    height: '36px',
    minWidth: '96px',
    borderRadius: '8px',
    backgroundColor: '#D9DCDE',
    color: '#FFFFFF',
    fontSize: '14px',
    fontWeight: 'bold',
    letterSpacing: 0,
    fontFamily: 'Inter',
    lineHeight: '20px',
    textAlign: 'center',
    '&:hover': {
      backgroundColor: '#D9DCDE',
      color: '#FFFFFF',
    },
  }));

  const AbleButton = styled(Button)<ButtonProps>(({ theme }) => ({
    height: '36px',
    minWidth: '96px',
    borderRadius: '8px',
    backgroundColor: '#009FB0',
    color: '#FFFFFF',
    fontSize: '14px',
    fontWeight: 'bold',
    letterSpacing: 0,
    fontFamily: 'Inter',
    lineHeight: '20px',
    textAlign: 'center',
    '&:hover': {
      backgroundColor: '#009FB0',
      color: '#FFFFFF',
    },
  }));

  const CheckInButton = styled(Button)<ButtonProps>(({ theme }) => ({
    height: '36px',
    minWidth: '130px',
    borderRadius: '8px',
    backgroundColor: '#1B3C84',
    color: '#FFFFFF',
    fontSize: '14px',
    fontWeight: 'bold',
    letterSpacing: 0,
    fontFamily: 'Inter',
    lineHeight: '20px',
    textAlign: 'center',
    '&:hover': {
      backgroundColor: '#1B3C84',
      color: '#FFFFFF',
    },
  }));

  const DisableCheckInButton = styled(Button)<ButtonProps>(({ theme }) => ({
    height: '36px',
    minWidth: '130px',
    borderRadius: '8px',
    backgroundColor: '#1B3C84',
    color: '#FFFFFF',
    fontSize: '14px',
    fontWeight: 'bold',
    letterSpacing: 0,
    fontFamily: 'Inter',
    lineHeight: '20px',
    textAlign: 'center',
    opacity: 0.2,
    '&:hover': {
      backgroundColor: '#1B3C84',
      color: '#FFFFFF',
    },
  }));

  const cardContainerStyle = {
    height: '214px',
    width: '445px',
    borderRadius: '8px',
    backgroundColor: '#FFFFFF',
    boxShadow: '0 5px 15px 0 rgba(0,0,0,0.08)',
    marginBottom: '26px',
  };
  const cardTitleContainerStyle = {
    width: '250px',
    display: 'flex',
    marginLeft: '32px',
  };
  const cardIconStyle = {
    fontSize: 30,
    color: '#2A394A',
    marginTop: '32px',
    marginBottom: '21px',
    marginRight: '9px',
  };

  const handleBack = () => {
    navigate(-1);
  };
  let attendeeCheckedName = '';
  const othersList: Array<any> = [];
  const registrationOption = infoAttendee?.attendee?.registrationOptionFulfillment?.items || [];
  if (registrationOption.length > 0) {
    const orderData = registrationOption[0].order?.registrationOptionFulfillment?.items || [];
    for (const item of orderData) {
      if (item?.attendee?.id !== attendeeId) {
        othersList.push({
          id: item?.attendee?.id,
          name: `${item?.attendee?.user?.firstName} ${item?.attendee?.user?.lastName}`,
          checked: item?.attendee?.checkIn,
          firstName: item?.attendee?.user?.firstName,
          lastName: item?.attendee?.user?.lastName,
          avatar: item?.attendee?.user?.avatar,
          registrationOption: item?.registrationOption?.name,
        });
      } else {
        attendeeCheckedName = `${item?.attendee?.user?.firstName} ${item?.attendee?.user?.lastName}`;
      }
    }
  }

  const renderAdvertising = (title, subtitle) => (
    <Box sx={{ height: '55px', width: '325px', marginLeft: '32px', marginBottom: '8px' }}>
      <Box flexDirection="row" sx={{ display: 'flex', width: '325px' }}>
        <Error sx={{ fontSize: 25, color: '#E93E3E' }} />
        <AttentionTitleStyle>{title}</AttentionTitleStyle>
      </Box>
      {subtitle && <AttentionSubTitleStyle>{subtitle}</AttentionSubTitleStyle>}
    </Box>
  );
  // Modal
  const onEditRegistrationOptions = () => {
    openModal(MODALS.ATTENDEES_REGISTRATION_OPTION_EDIT_MODAL);
  };

  const openDonateModal = () => {
    openModal(MODALS.ATTENDEES_DONATION);
  };

  const onAttendeeCheckin = async () => {
    try {
      const updateAttendeeChecked = await attendeChecked({
        variables: {
          attendeeId,
          checked: true,
        },
      });
      // return updateAttendeeChecked;
    } catch (error) {
      console.error({ error });
      throw error;
    }
    openModal(MODALS.ATTENDEE_CHECKIN);
  };

  const onAttendeeStore = () => {
    openModal(MODALS.ATTENDEE_STORE);
  };

  const onAttendeeWaiver = async () => {
    openModal(MODALS.ATTENDEES_WAIVER);
  };

  const onAttedeeSwagbag = async () => {
    openModal(MODALS.ATTENDEE_SWAGBAG);
  };

  const onAttendeeReceipt = async () => {
    openModal(MODALS.ATTENDEES_REGISTRATION_RECEIPT);
  };

  const renderRegistrationCard = () => {
    if (isNil(registration.registrationOption)) {
      return null;
    }

    const id = infoAttendee?.attendee?.registrationOptionFulfillment?.items[0]?.order?.number || 0;
    const date =
      infoAttendee?.attendee?.registrationOptionFulfillment?.items[0]?.order?.createdAt || 'N/A';
    const status =
      infoAttendee?.attendee?.registrationOptionFulfillment?.items[0]?.order?.payment?.status ||
      'N/A';
    const amount =
      infoAttendee?.attendee?.registrationOptionFulfillment?.items[0]?.order
        ?.registrationOptionFulfillment?.items[0]?.amount || 0;
    const description =
      infoAttendee?.attendee?.registrationOptionFulfillment?.items[0]?.order
        ?.registrationOptionFulfillment?.items[0]?.registrationOption?.experience?.name || 'N/A';
    const userName = infoAttendee?.attendee?.user?.firstName || 'N/A';

    const email = infoAttendee?.attendee?.user?.email;

    return (
      <Box sx={cardContainerStyle}>
        <Box flexDirection="row" sx={cardTitleContainerStyle}>
          <Receipt sx={cardIconStyle} />
          <CardTitleStyled>Registration</CardTitleStyled>
        </Box>
        <CardTextStyled>{registration.registrationOption}</CardTextStyled>
        <Box flexDirection="row" sx={{ width: '200px', display: 'flex', marginLeft: '16px' }}>
          <SimpleStyledButton onClick={onEditRegistrationOptions}>Change</SimpleStyledButton>
          <SimpleStyledButton onClick={onAttendeeReceipt}>Receipt</SimpleStyledButton>
        </Box>
        <AttendeesRegistrationEditModal setRegistration={setRegistration} />
        <AteendeesRegistrationReceiptModal
          id={id}
          date={date}
          status={status}
          amount={amount}
          description={description}
          userName={userName}
          email={email}
        />
      </Box>
    );
  };

  const renderFundraisingCard = () => {
    if (fundraising.alert.title === '') {
      return null;
    }

    return (
      <Box sx={cardContainerStyle}>
        <Box flexDirection="row" sx={cardTitleContainerStyle}>
          <PriceCheck sx={cardIconStyle} />
          <CardTitleStyled>Fundraising</CardTitleStyled>
        </Box>
        {fundraising?.alert &&
          renderAdvertising(fundraising.alert.title, fundraising.alert.subtitle)}
        <Box flexDirection="row" sx={{ width: '200px', display: 'flex', marginLeft: '25px' }}>
          {fundraising.fundraising === false && fundraising.override === false && (
            <AttentionStyledButton onClick={openDonateModal}>Donate</AttentionStyledButton>
          )}
          {fundraising.fundraising === false && fundraising.override === true && (
            <AbleButton>Donate</AbleButton>
          )}
          {fundraising.fundraising === true && fundraising.override === true && (
            <SimpleStyledButton>Donate</SimpleStyledButton>
          )}
          <AttendeesDonation />
          <SimpleStyledButton>View Page</SimpleStyledButton>
          <Box flexDirection="row" sx={{ display: 'flex' }}>
            <Checkbox
              onChange={() => setFundraising({ ...fundraising, override: !fundraising.override })}
            />
            <OverrideTextStyled>Override</OverrideTextStyled>
          </Box>
        </Box>
      </Box>
    );
  };

  // assignBibNumber
  const assignBibNumber = async () => {
    try {
      const updateBibNumber = await bibAssignmentByAttendeeMutation({
        variables: {
          experienceId: experienceId || '',
          attendeeId: attendeeId || '',
        },
      });
      refetchAttendeeData();
      setBibNumber({
        ...bibNumber,
        bibNumber: updateBibNumber.data?.bibAssignByAttendee?.bibNumber || -1,
      });
      notification.success('bib_number_updated');
    } catch (error) {
      console.error(error);
      notification.error('bib_number_update_error');
    }
  };
  const renderBibNumberCard = () => {
    if (bibNumber.bibNumber < 0) {
      return null;
    }
    return (
      <Box sx={cardContainerStyle}>
        <Box flexDirection="row" sx={cardTitleContainerStyle}>
          <ContactPage sx={cardIconStyle} />
          <CardTitleStyled>Bib Number</CardTitleStyled>
        </Box>
        {bibNumber.bibNumber === 0 ? (
          renderAdvertising(bibNumber.alert.title, bibNumber.alert.subtitle)
        ) : (
          <CardTextStyled>{bibNumber.bibNumber > 0 && bibNumber.bibNumber}</CardTextStyled>
        )}
        <Box flexDirection="row" sx={{ width: '200px', display: 'flex', marginLeft: '25px' }}>
          {bibNumber.bibNumber ? (
            <SimpleStyledButton onClick={assignBibNumber} disabled={loadingBib}>
              {loadingBib && <CircularProgress size={14} />}
              {!loadingBib && 'Change'}
            </SimpleStyledButton>
          ) : (
            <AttentionStyledButton onClick={assignBibNumber} disabled={loadingBib}>
              {loadingBib && <CircularProgress size={14} />}
              {!loadingBib && 'Assign'}
            </AttentionStyledButton>
          )}
        </Box>
      </Box>
    );
  };
  const renderWaiverCard = () => {
    if (isNil(waiver.waiver)) {
      return (
        <Box sx={cardContainerStyle}>
          <Box flexDirection="row" sx={cardTitleContainerStyle}>
            <Article sx={cardIconStyle} />
            <CardTitleStyled>Waiver</CardTitleStyled>
          </Box>

          <CardTextStyled>Waiver is not required</CardTextStyled>
        </Box>
      );
    }
    const waiverText = infoAttendee?.attendee?.experience?.waiverTextBody ?? '';
    const signature = infoAttendee?.attendee?.waiverSignature ?? '';
    const signedAt = infoAttendee?.attendee?.waiverSignedAt ?? null;
    const waiverButtonIsLoading = waiverMutationOptions.loading || loadingInfoAttendee;
    return (
      <Box sx={cardContainerStyle}>
        <Box flexDirection="row" sx={cardTitleContainerStyle}>
          <Article sx={cardIconStyle} />
          <CardTitleStyled>Waiver</CardTitleStyled>
        </Box>
        <Box flexDirection="row" width={25} display="flex" marginLeft={3}>
          <SimpleStyledButton loading={waiverButtonIsLoading} onClick={onAttendeeWaiver}>
            View
          </SimpleStyledButton>
        </Box>
        <AttendeeWaiverModal
          onSubmit={onWaiverSign}
          waiverBody={waiverText}
          signedAt={signedAt}
          signature={signature}
        />
      </Box>
    );
  };
  const renderSwagBagCard = () => {
    if (isNil(swagBag.items)) {
      return null;
    }
    const swagBagComplete = swagBag.items.filter(item => item?.status === 'Completed');
    return (
      <Box sx={cardContainerStyle}>
        <Box flexDirection="row" sx={cardTitleContainerStyle}>
          <LocalMall sx={cardIconStyle} />
          <CardTitleStyled>Swag Bag</CardTitleStyled>
        </Box>
        <CardTextStyled>{swagBagComplete.length} items</CardTextStyled>
        <Box flexDirection="row" sx={{ width: '200px', display: 'flex', marginLeft: '25px' }}>
          <SimpleStyledButton onClick={onAttedeeSwagbag}>View</SimpleStyledButton>
        </Box>
        {!loadingInfoAttendee && (
          <AttendeeSwagBagModal
            swagBag={swagBag.items}
            regOptionId={regOptionId}
            orderId={orderId}
          />
        )}
      </Box>
    );
  };

  const renderPurchasesCard = () => {
    const { store } = purchases;
    let totalItems = 0;
    let total: number | string = 0;
    if (store && store?.length > 0) {
      totalItems = store?.length;

      for (const item of store) {
        total += item?.amount;
      }
      total = (Math.round(total * 100) / 100).toFixed(2);
    }
    return (
      <Box sx={cardContainerStyle}>
        <Box flexDirection="row" sx={cardTitleContainerStyle}>
          {/* <CreditCard sx={cardIconStyle} /> */}
          <CardTitleStyled>Purchases</CardTitleStyled>
        </Box>
        <Box flexDirection="row" sx={{ display: 'flex' }}>
          <CardTextStyled>
            {totalItems} items Total: ${total}
          </CardTextStyled>
        </Box>
        <Box flexDirection="row" sx={{ width: '200px', display: 'flex', marginLeft: '25px' }}>
          <SimpleStyledButton onClick={onAttendeeStore}>View</SimpleStyledButton>
          <AttendeeStore purchases={purchases} />
        </Box>
      </Box>
    );
  };

  const OtherPeople = othersList => (
    <Box
      sx={{
        width: 255,
        marginLeft: 0,
        padding: '-20px',
      }}
    >
      <OthersStyledTittle>Other People on Registration ({othersList.length})</OthersStyledTittle>
      <Box>
        {othersList.map(otherUser => (
          <PeopleInformationItem
            key={otherUser.id}
            iconName="AccountCircle"
            label={otherUser.name}
            checked={otherUser.checked}
          />
        ))}
      </Box>
    </Box>
  );

  // set data shoppinCart
  const onCheckout = async () => {
    try {
      setLoadingCheckout(true);
      const destination = buildUrl(appRoutes.checkinExperiencePageCheckout, {
        experienceId,
        parentOrderId: orderId || '',
      });
      navigate(destination);
      setLoadingCheckout(false);
    } catch (error) {
      console.error(error);
      notification.error('Somethig is wrong in checkout process');
    }
  };

  return (
    <Box>
      <Grid container gap={2} flexDirection="column" flexWrap="nowrap">
        <Grid justifyContent="space-between" item xs={11.8} sx={{ display: 'flex' }}>
          <StyledButton
            onClick={handleBack}
            variant="outlined"
            sx={{
              fontSize: '14px',
              fontWeight: 'bold',
              borderColor: '#009FB0',
              letterSpacing: 0,
              fontFamily: 'Inter',
              lineHeight: '20px',
              '&:hover': {
                borderColor: '#009FB0',
              },
            }}
            startIcon={<ArrowBackRounded sx={{ marginRigth: '0px', padding: '0px' }} />}
          >
            Back to Search
          </StyledButton>
          <Box sx={{ display: 'inline-flex', alignItems: 'self-end', width: '340px' }}>
            {checkIn ? (
              <Box>
                <CheckInButton startIcon={<CheckCircleOutline />} onClick={onAttendeeCheckin}>
                  Check In
                </CheckInButton>
                <AttendeeCheckin
                  attenddeChecked={attendeeCheckedName}
                  experience={experienceId}
                  data={othersList}
                />
              </Box>
            ) : (
              <DisableCheckInButton startIcon={<CheckCircleOutline />}>
                Check In
              </DisableCheckInButton>
            )}

            <AttendeeCheckinCart onCheckout={onCheckout} loading={loadingCheckout} />
          </Box>
        </Grid>
        <Grid spacing={5} flexDirection="row" container>
          <Grid item>
            <Box
              sx={{
                fontSize: '11px',
                fontWeight: 'bold',
                borderColor: '#009FB0',
                letterSpacing: 0,
                fontFamily: 'Inter',
                lineHeight: '20px',
              }}
            >
              <UserInformationCard
                user={userData}
                bottomComponent={OtherPeople(othersList)}
                onEditIconClick={() => openModal(MODALS.ATTENDEE_EDIT_USERINFO_MODAL)}
              />
              <AttendeesEdituserInfoModal />
            </Box>
          </Grid>
          <Grid item>
            <Box
              flexWrap="wrap"
              flexDirection="row"
              justifyContent="space-between"
              sx={{
                display: 'flex',
                width: '910px',
              }}
            >
              {renderRegistrationCard()}
              {renderFundraisingCard()}
              {renderBibNumberCard()}
              {renderWaiverCard()}
              {renderSwagBagCard()}
              {renderPurchasesCard()}
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export { AttendeeCheckinPageLayout };
