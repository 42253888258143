import type { RouteProps } from '@vizsla/types';

import * as appRoutes from 'src/constants/routes';

import {
  CheckinCampaignsPage,
  ExperiencePageRoot,
  ExperienceAttendeesPage,
  ExperienceAttendeeCheckinPage,
  CheckoutPage,
  CheckinPaymentPage,
} from './checkin';

export const STATIC_ROUTES: Array<RouteProps> = [
  {
    path: appRoutes.checkin,
    element: CheckinCampaignsPage,
  },
  {
    path: appRoutes.checkinExperiencePageCheckout,
    element: CheckoutPage,
  },
  {
    path: appRoutes.checkinExperiencePagePayment,
    element: CheckinPaymentPage,
  },
  {
    path: appRoutes.checkinExperiencePage,
    element: ExperiencePageRoot,
  },
  {
    path: appRoutes.checkinExperienceAttendeesPage,
    element: ExperienceAttendeesPage,
    childRoutes: [],
  },
  {
    path: appRoutes.checkinExperienceAttendeeCheckinPage,
    element: ExperienceAttendeeCheckinPage,
  },
];
