import React from 'react';
import { useParams } from 'react-router-dom';
import { Skeleton } from '@mui/material';

import { useCurrentAttendeeQuery } from '@vizsla/graphql';

import { Container, DisclaimerText, ExperienceText } from './HeaderAttendeeDonation.style';

export function HeaderAttendeeDonation() {
  const { attendeeId } = useParams();

  const { data } = useCurrentAttendeeQuery({
    variables: { attendeeid: attendeeId },
  });

  const currentAttendee = data?.attendee?.user;

  if (!data || !attendeeId) {
    return (
      <Container>
        <Skeleton variant="text" width={180} height={52} />
        <Skeleton variant="text" width={80} height={32} />
      </Container>
    );
  }

  return (
    <Container>
      <DisclaimerText>You are donating to</DisclaimerText>
      <ExperienceText>
        {currentAttendee?.firstName} {currentAttendee?.lastName}
      </ExperienceText>
    </Container>
  );
}
