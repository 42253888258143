import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { PaymentGateway } from '@vizsla/components';
import { useNotification, useShoppingCart } from '@vizsla/hooks';
import { buildUrl } from '@vizsla/utils';

import * as appRoutes from 'src/constants/routes';

import { Container, Header, HeaderTitle } from './Payment.styles';

export function PaymentPage() {
  const { experienceId } = useParams();
  const navigate = useNavigate();
  const notification = useNotification();
  const { clean: cleanShoppingCart } = useShoppingCart();

  const handleSucceeded = () => {
    const destination = buildUrl(appRoutes.checkinExperienceAttendeesPage, { experienceId });
    notification.success('Successfully Checkout');
    cleanShoppingCart();
    navigate(destination);
  };

  return (
    <React.Fragment>
      <Container>
        <Header>
          <HeaderTitle>Payment Details</HeaderTitle>
        </Header>

        <PaymentGateway onSucceeded={handleSucceeded} />
      </Container>
    </React.Fragment>
  );
}
