import { gql } from '@apollo/client';

export const ORDERS_FRAGMENT_CHECKIN = gql`
  fragment OrderFragmentCheckin on Order {
    id
    status
    reasonStatus
    totalAmount
    user {
      id
      firstName
      lastName
    }
  }
`;

export const NEW_ORDER_SHOPPING_CART_CHECKIN_MUTATION = gql`
  mutation NewOrderShoppingCartCheckin($data: OrderCreateInput!) {
    orderCreate(data: $data) {
      ...OrderFragmentCheckin
    }
  }
  ${ORDERS_FRAGMENT_CHECKIN}
`;

export const NEW_ORDER_ITEM_CHECKIN = gql`
  mutation NewOrderItemCheckin($data: OrderItemCreateInput!) {
    orderItemCreate(data: $data) {
      id
      description
      category
      transactionType
      amount
      order {
        id
      }
    }
  }
`;

export const NEW_ORDER_HISTORY_CHECKIN_MUTATION = gql`
  mutation NewStatusOrderCheckin($data: OrderHistoryCreateInput!) {
    orderHistoryCreate(data: $data) {
      id
      status
      reason
      order {
        id
      }
    }
  }
`;

export const NEW_REGISTRATION_OPTION_FULFILLMENT_CHECKIN_MUTATION = gql`
  mutation NewRegistratioOptionFullfimentCheckin($data: RegistrationOptionFulfillmentCreateInput!) {
    registrationOptionFulfillmentCreate(data: $data) {
      id
      status
      amount
      attendee {
        id
      }
      order {
        id
      }
    }
  }
`;
export const NEW_ASSET_FULFILLMENT_CHECKIN_MUTATION = gql`
  mutation NewAssetFullfimentCheckin($data: AssetFullfillmentCreateInput!) {
    assetFullfillmentCreate(data: $data) {
      id
      status
      amount
      attendee {
        id
      }
      order {
        id
      }
    }
  }
`;
export const NEW_SWAGBAG_FULFILLMENT_CHECKIN_MUTATION = gql`
  mutation NewSwagbagFullfimentCheckin($data: AssetSwagbagFulfillmentCreateInput!) {
    assetSwagbagFulfillmentCreate(data: $data) {
      id
      status
      amount
      attendee {
        id
      }
      order {
        id
      }
    }
  }
`;

export const NEW_PAYMENT_ORDER_CHECKIN_MUTATION = gql`
  mutation NewPaymentOrderCheckin($data: PaymentCreateInput!) {
    paymentCreate(data: $data) {
      id
      status
      gateway
      method
      category
      reasonStatus
      totalAmount
      order {
        id
      }
    }
  }
`;

export const NEW_DONATION_ORDER_EXPERIENCE_CHECKIN = gql`
  mutation NewDonationOrderCheckin($data: ExperienceDonationCreateInput!) {
    experienceDonationCreate(data: $data) {
      id
      amount
    }
  }
`;

export const NEW_DONATION_ORDER_INDIVIDUAL_CHECKIN = gql`
  mutation NewDonationOrderIndividualCheckin($data: IndividualFundraisingDonationCreateInput!) {
    individualFundraisingDonationCreate(data: $data) {
      id
      amount
    }
  }
`;

export const NEW_ORDER_INTENT = gql`
  mutation NewOrderIntent($data: OrderIntentCreateInput!) {
    orderIntentCreate(data: $data) {
      id
      data
      parentOrderId
    }
  }
`;

export const ORDER_INTENT_TO_ORDER = gql`
  mutation OrderIntentToOrder($data: OrderIntentToOrderInput!) {
    orderIntentToOrder(data: $data) {
      success
    }
  }
`;
