import React from 'react';
import ReactQRScanner from 'react-qr-barcode-scanner';

import { useNotification } from '@vizsla/hooks';

import {
  CircularProgress,
  Container,
  Content,
  Disclaimer,
  InnerDisclaimer,
} from './AttendeesQrScanner.style';

interface AttendeesQrScannerProps {
  onRead?(text: string): void;
}

export function AttendeesQrScanner(props: AttendeesQrScannerProps) {
  const [loading, setLoading] = React.useState(true);
  const [granted, setGranted] = React.useState(true);

  const toast = useNotification();

  const handleUpdate = (content: string | undefined) => {
    if (loading) setLoading(false);

    if (typeof content === 'string') {
      props.onRead?.(content);
    }
  };

  const handleError = (err: unknown) => {
    if (loading) setLoading(false);

    if (err instanceof Error) {
      if (err.name === 'NotAllowedError') {
        toast.error('No permission granted for QR camera.');

        setLoading(false);
        setGranted(false);
      }
    }
  };

  return (
    <Container>
      <Content>
        <ReactQRScanner
          onError={handleError}
          onUpdate={(_, result) => {
            const text = result?.getText();
            handleUpdate(text);
          }}
        />

        {loading && <CircularProgress />}

        {!granted && (
          <InnerDisclaimer>You have not granted permissions to access the camera.</InnerDisclaimer>
        )}
      </Content>

      <Disclaimer>Make sure that the attendee is from this experience.</Disclaimer>
    </Container>
  );
}
