import React from 'react';
import { Box } from '@mui/material';
import _ from 'lodash';

import { SearchInput } from '@vizsla/components';

import { useAttendeesTableData } from 'src/hooks/context';

const AttendeesSearchInput: React.FC = () => {
  const { setSearchValue } = useAttendeesTableData();

  const debouncedSetSearchValue = _.debounce(setSearchValue, 500);

  return (
    <Box>
      <SearchInput
        onChange={debouncedSetSearchValue}
        placeholder="Search by Attendee or Team or Bib Number"
      />
    </Box>
  );
};

export { AttendeesSearchInput };
