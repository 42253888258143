import React from 'react';

import { useCurrentUser, useExperienceId, useModal, useShoppingCart } from '@vizsla/hooks';
import { Button, Form, Modal, DonationForm } from '@vizsla/components';
import { validateWithSchema } from '@vizsla/utils';
import { DonationFrecuency } from '@vizsla/types';

import { MODALS } from 'src/constants/modals';
import { DONATION_FORM_SCHEMA } from 'src/constants/validationSchemas/Donation';
import { HeaderAttendeeDonation } from 'src/components/HeaderAttendeeDonation';

import { Content, Buttons } from './AttendeeDonation.style';

interface DonationFormValues {
  amount?: number;
  frecuency?: DonationFrecuency;
  message?: string;
  makeAnonymous?: boolean;
  companyName?: string;
}

const FORM_INITIAL_VALUES: DonationFormValues = {
  frecuency: DonationFrecuency.ONE_TIME,
};

const AttendeesDonation: React.FC = () => {
  const [loading, setLoading] = React.useState(false);

  const { isOpen, closeModal } = useModal(MODALS.ATTENDEES_DONATION);
  const { add: addToCart } = useShoppingCart();
  const { user } = useCurrentUser();

  const experienceId = useExperienceId();

  const validateForm = (values: DonationFormValues) => {
    return validateWithSchema(DONATION_FORM_SCHEMA, values);
  };

  const handleSubmit = async (values: DonationFormValues) => {
    setLoading(true);

    const price = values.amount ?? 0;
    const makeAnonymous = values.makeAnonymous ?? false;

    try {
      if (!user?.id) {
        throw new Error("There's no any user selected.");
      }

      addToCart({
        type: 'donation',
        donor: { id: user.id },
        allocation: { id: experienceId, type: 'experience' },
        makeAnonymous,
        price,
      });

      closeModal();
    } catch (error) {
      console.error({ error });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal isOpen={isOpen} slideDirection="left" onClose={closeModal} fullWidth>
      <Modal.Content>
        <HeaderAttendeeDonation />
        <Form initialValues={FORM_INITIAL_VALUES} validate={validateForm} onSubmit={handleSubmit}>
          {form => (
            <Content>
              <DonationForm experienceId={experienceId} />

              <Buttons>
                <Button
                  loading={loading}
                  onClick={form.handleSubmit}
                  disabled={loading || form.invalid}
                >
                  Add to Cart
                </Button>
              </Buttons>
            </Content>
          )}
        </Form>
      </Modal.Content>
    </Modal>
  );
};

export { AttendeesDonation };
