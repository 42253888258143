/* eslint-disable react/react-in-jsx-scope -- Unaware of jsxImportSource */
/** @jsxImportSource @emotion/react */

import React from 'react';
import { css, jsx } from '@emotion/react';
import {
  Card,
  CardContent,
  Grid,
  RadioGroup,
  Radio,
  FormControlLabel,
  Theme,
  Typography,
} from '@mui/material';

import { DonationOrRefund } from './DonateOrRefundRegOption';

const gridItems = css`
  display: grid;
  justify-content: center;
`;
const cardItem = css`
  display: grid;
  align-items: center;
  height: 96px;
  width: 501px;
  box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.1);
  margin: 10px;
`;

const cardContentItem = css`
  display: grid;
  grid-template-columns: 2fr 1fr;
  align-items: center;
`;

const TypographyTitle = css`
  font-family: Inter;
  font-size: 20px;
  font-weight: bold;
  letter-spacing: -0.29px;
  line-height: 20px;
`;
const TypographyDescription = css`
  font-family: Inter;
  font-size: 14px;
  letter-spacing: -0.2px;
  line-height: 20px;
`;

const TypographyPay = css`
  display: grid;
  justify-content: end;
  font-family: Inter;
  font-size: 20px;
  letter-spacing: -0.29px;
  line-height: 24px;
`;

// eslint-disable-next-line prettier/prettier
export const ItemAttendeesRegistrationEdit = ({ attendee, setRegistrationDataUpdate, setRefundOrDonate }) => {
  const registrationOptions = attendee?.experience?.registrationOptions?.items ?? [];
  const [registarattionOptionDeafult, setRegistarattionOptionDeafult] = React.useState(
    attendee?.registrationOption?.id,
  );
  const [DonateOrRefund, setDonateRefund] = React.useState(false);
  const [typeDonationRefund, setTypeDonationRefund] = React.useState({
    type: 'Donation',
    valid: false,
    price: '0',
  });

  React.useEffect(() => {
    setRefundOrDonate(typeDonationRefund);
  }, [typeDonationRefund]);
  const validDisplay = (optionPay: any, currentOption: any) => {
    try {
      let valid = false;
      if (optionPay.pricingSettings.pricingType === 'Fixed') {
        valid = true;
      }
      if (optionPay.pricingSettings.pricingType === 'Scaled') {
        const currentDate = new Date();
        if (optionPay.pricingSettings.pricingTiers.items.length === 0) {
          return true;
        }
        for (const element of optionPay.pricingSettings.pricingTiers.items) {
          const startDate = new Date(element.startDate);
          const endDate = new Date(element.endDate);

          if (
            (startDate <= currentDate && endDate >= currentDate) ||
            optionPay.id === currentOption.id
          ) {
            valid = true;
            break;
          }
        }
      }
      return valid;
    } catch (error) {
      return false;
    }
  };

  const registrationOptionPay = (optionPay: any, currentOption: any) => {
    try {
      const currentDate = new Date();

      if (optionPay.pricingSettings.pricingType === 'Fixed') {
        if (optionPay.pricingSettings.pricingTiers.items.length === 0) {
          return { price: 0, text: 'FREE' };
        }

        return optionPay.pricingSettings.pricingTiers.items[0].price === 0
          ? { price: 0, text: 'FREE' }
          : {
              price: optionPay.pricingSettings.pricingTiers.items[0].price,
              text: `$${optionPay.pricingSettings.pricingTiers.items[0].price.toFixed(2)}`,
            };
      }
      if (optionPay.pricingSettings.pricingType === 'Scaled') {
        if (optionPay.pricingSettings.pricingTiers.items.length === 0) {
          return { price: 0, text: 'FREE' };
        }

        let price = 0;
        let text = 'N/A';
        for (const element of optionPay.pricingSettings.pricingTiers.items) {
          const startDate = new Date(element.startDate);
          const endDate = new Date(element.endDate);

          if (
            (startDate <= currentDate && endDate >= currentDate) ||
            optionPay.id === currentOption.id
          ) {
            if (element.price === 0) {
              price = 0;
              text = 'FREE';
              break;
            } else {
              let discount = 0;
              price = element.price;
              if (element.taxDeductibleType === 'Percents') {
                discount = (price * element.taxDeductiblePercentsAmount) / 100;
                price -= discount;
              }
              if (element.taxDeductibleType === 'Currency') {
                discount = element.taxDeductibleCurrencyAmount;
                price -= discount;
              }
            }
            text = `$${price.toFixed(2)}`;
          }
        }
        return { price, text };
      }

      return { price: 0, text: 'N/A' };
    } catch (error) {
      return { price: 0, text: 'N/A' };
    }
  };

  const descriptionRegistrationOptions = (optionPay: any, currentOption: any) => {
    try {
      if (optionPay.id === currentOption.id) {
        return 'Current registration option';
      }
      const currentPrice = registrationOptionPay(currentOption, currentOption).price;
      const optionPrice = registrationOptionPay(optionPay, currentOption).price;
      if (currentPrice === optionPrice) {
        return 'You will be credited $0';
      }
      if (currentPrice > optionPrice) {
        const credit = (currentPrice - optionPrice).toFixed(2);
        return `You will be credited $${credit}`;
      }
      if (currentPrice < optionPrice) {
        const credit = (optionPrice - currentPrice).toFixed(2);
        return `Add additional $${credit}`;
      }
      return 'N/A';
    } catch (error) {
      return 'N/A';
    }
  };

  const handleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setRegistarattionOptionDeafult(value);

    if (value !== attendee?.registrationOption.id) {
      const currentRegOption = registrationOptions.filter(
        item => item.id === attendee?.registrationOption.id,
      )[0];
      const currentPay = registrationOptionPay(
        currentRegOption,
        attendee?.registrationOption,
      ).price;
      const registrationData = registrationOptions.filter(item => item.id === value)[0];
      const registrationPrice = registrationOptionPay(
        registrationData,
        attendee?.registrationOption,
      ).price;
      const price = registrationPrice - currentPay < 0 ? 0 : registrationPrice - currentPay;
      setRegistrationDataUpdate({
        type: 'registration',
        name: registrationData.name,
        price,
        option: {
          id: registrationData.id,
          name: registrationData.name,
        },
      });
      if (registrationPrice < currentPay) {
        setDonateRefund(true);
        setRefundOrDonate({
          ...typeDonationRefund,
          valid: true,
          price: ((registrationPrice - currentPay) * -1).toFixed(2),
        });
        setTypeDonationRefund({
          ...typeDonationRefund,
          valid: true,
          price: ((registrationPrice - currentPay) * -1).toFixed(2),
        });
      } else {
        setDonateRefund(false);
        setRefundOrDonate({ ...typeDonationRefund, valid: false, price: 0 });
        setTypeDonationRefund({ ...typeDonationRefund, valid: false, price: '0' });
      }
    }
  };

  return (
    <Grid css={gridItems}>
      <RadioGroup
        name="dateType"
        defaultValue={registarattionOptionDeafult}
        value={registarattionOptionDeafult}
        onChange={handleChange}
      >
        {registrationOptions.map(item => {
          if (validDisplay(item, attendee?.registrationOption)) {
            return (
              <Card css={cardItem} key={item.id}>
                <CardContent css={cardContentItem}>
                  <FormControlLabel
                    label={
                      <div>
                        <Typography css={TypographyTitle}> {item.name} </Typography>
                        <Typography css={TypographyDescription}>
                          {' '}
                          {descriptionRegistrationOptions(item, attendee?.registrationOption)}{' '}
                        </Typography>
                      </div>
                    }
                    control={<Radio />}
                    value={item.id}
                  />
                  <Typography css={TypographyPay}>
                    {registrationOptionPay(item, attendee?.registrationOption).text}
                  </Typography>
                </CardContent>
              </Card>
            );
          }
          return '';
        })}
      </RadioGroup>
      {DonateOrRefund && (
        <DonationOrRefund
          typeDonationRefund={typeDonationRefund}
          setTypeDonationRefund={setTypeDonationRefund}
        />
      )}
    </Grid>
  );
};
